import React, { useState } from "react";

function Textarea({ inputProps, label, onChange, errorMessage, value }) {
  const [focus, setFocus] = useState(false);
  return (
    <div className="inputWrapper ">
      {label && (
        <label htmlFor={inputProps.id}>
          {label}
          {inputProps?.required ? <i style={{ color: "red" }}>*</i> : ""}
        </label>
      )}
      <textarea
        className="form-control"
        {...inputProps}
        value={value || ""}
        onChange={(e) => onChange(e)}
        onBlur={() => {
          setFocus(true);
        }}
        focus={focus.toString()}
      />
      <span className="inputError">{errorMessage}</span>
    </div>
  );
}

export default Textarea;
