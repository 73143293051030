import React, { useContext, useEffect, useState } from "react";
import Card from "../components/Card/Card";
import "react-quill/dist/quill.snow.css";
import Input from "../components/Inputs/Input";
import QuillInput from "../components/Inputs/QuillInput";
import { JournalContext } from "../store/JournalContext";
import { errorToast, successToast } from "../HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../components/ButtonWithLoader/ButtonWithLoader";
import { updateAppSettingValues } from "../endpoints/AppsettingsEndpoints";

const EditJournal = () => {
  const {
    setAppIsReady,
    journalData,
    setJournalCode,
    setJournalName,
    journalCode,
    journalName,
    bannerImages,
  } = useContext(JournalContext);
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  useEffect(() => {
    setAppIsReady(true);
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);

    updateAppSettingValues(journalData, journalCode, journalName, bannerImages)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  return (
    <div className="main-content">
      <Card>
        <h3>Edit Journal</h3>
        <form onSubmit={handleSave}>
          <div className="mt-2 row">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "journalCode",
                  id: "journalCode",
                  required: true,
                }}
                value={journalCode}
                onChange={(e) => setJournalCode(e.target.value)}
                label={"Code"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "journalName",
                  id: "journalName",
                  required: true,
                }}
                value={journalName}
                onChange={(e) => setJournalName(e.target.value)}
                label={"Name"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
          </div>

          <div className="row accordion">
            <i>
              Turn on toggle switch to display additional information in
              journal's portal.
            </i>
            {journalData.map((journalField) => {
              return (
                <QuillInput
                  label={journalField.key}
                  id={journalField.id}
                  key={journalField.id}
                />
              );
            })}
          </div>
          <div className="d-flex justify-content-end mt-3">
            <ButtonWithLoader isLoading={isRequestBeingMade}>
              Save Journal
            </ButtonWithLoader>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditJournal;
