import React, { useState } from "react";
import Input from "../components/Inputs/Input";
import Card from "../components/Card/Card";
import { resetPassword } from "../endpoints/UserEndpoints";
import { errorToast, successToast } from "../HelperFunctions/HelperFunctions";
import { useNavigate, useParams } from "react-router-dom";
import ButtonWithLoader from "../components/ButtonWithLoader/ButtonWithLoader";

const ForgotPassword = () => {
  const { token } = useParams();
  const [values, setValues] = useState({
    password: "",
    confirmNewPassword: "",
    token: token,
  });
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    resetPassword(values)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          navigate("/auth/login");
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  function handleChange(e) {
    setValues((prev) => {
      const name = e.target.name || e.target.title;
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  }

  return (
    <div className="w-50 m-auto">
      <Card>
        <form onSubmit={handleSubmit}>
          <div className="m-auto w-75">
            <h4 className="text-center">Reset Password</h4>
            <div className="my-3">
              <Input
                key={"password"}
                inputProps={{
                  type: "password",
                  name: "password",
                  id: "password",
                  pattern: "^.{7,}$",
                  required: true,
                }}
                value={values.password}
                onChange={handleChange}
                label={"Password"}
                errorMessage={"Password must contain at least 7 characters"}
              />
              <Input
                key={"confirmNewPassword"}
                inputProps={{
                  type: "password",
                  name: "confirmNewPassword",
                  id: "confirmNewPassword",
                  pattern: values.password,
                  required: true,
                }}
                value={values.confirmNewPassword}
                onChange={handleChange}
                label={"Confirm Password"}
                errorMessage={"Passwords do not match"}
              />
            </div>
            <div className="d-flex flex-column align-items-center mb-5">
              <ButtonWithLoader
                isLoading={isRequestBeingMade}
                classNames={["login-btn"]}
              >
                Reset
              </ButtonWithLoader>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default ForgotPassword;
