import React from "react";
import { TiDeleteOutline } from "react-icons/ti";

const ImagesDisplay = ({ imageList, handleDeleteImage }) => {
  return (
    <>
      {imageList.map((img, index) => (
        <div key={index} className="imageWrapper">
          <img
            src={`data:${img.imageContentType};base64,${img.image}`}
            alt={img.imageName}
          />
          <TiDeleteOutline
            color="red"
            className="remove-image-icon"
            onClick={() => handleDeleteImage(index)}
          />
        </div>
      ))}
    </>
  );
};

export default ImagesDisplay;
