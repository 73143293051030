import React, { useState, useEffect } from "react";
import Card from "../components/Card/Card";
import { getJournalUsers, deleteUser } from "../endpoints/UserEndpoints";
import DeleteModal from "../components/Modals/DeleteModal";
import { errorToast, successToast } from "../HelperFunctions/HelperFunctions";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import EmptyResponse from "../components/EmptyResponse/EmptyResponse";
import Table from "../components/Table/Table";

const UsersList = ({ userRole }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    getJournalUsers(userRole)
      .then((res) => {
        const copiedArray = res.data.users.map((obj) => ({
          firstName: obj.firstName,
          lastName: obj.lastName,
          email: obj.email,
          phoneNo: obj.phoneNo,
          id: obj.id,
        }));
        setUsers(copiedArray);
      })
      .finally(() => setLoading(false));
  }, [userRole]);

  const handleDeleteUser = (userId) => {
    deleteUser(userId).then((res) => {
      if (res.data.success) {
        successToast(res.data.message);
        const newUsers = users.filter((obj) => !userId.includes(obj.id));
        setUsers(newUsers);
        toggleModal();
      } else {
        errorToast(res.data.message);
      }
      toggleModal();
    });
  };

  const toggleModal = (userID) => {
    setSelectedUserId(userID);
    if (showModal) {
      setShowModal(false);
      document.body.style.overflow = "unset";
    } else {
      setShowModal(true);
      document.body.style.overflow = "hidden";
    }
  };

  const tableHeaders = [
    { value: "First Name" },
    { value: "Last Name" },
    { value: "Email" },
    { value: "Contact Number" },
  ];

  return (
    <div className="main-content">
      <DeleteModal
        show={showModal}
        onCancel={toggleModal}
        onConfirm={() => handleDeleteUser(selectedUserId)}
        name={"user"}
      />
      <Card>
        <h3>Admin Users List</h3>
        {loading && (
          <LoadingSpinner
            color={"var(--main800)"}
            height={"30px"}
            width={"30px"}
          />
        )}
        {!loading && users.length > 0 && (
          <Table
            tableHeaders={tableHeaders}
            tableData={users}
            editNavigateLink={"/adduser"}
            toggleModal={toggleModal}
          />
        )}
        {!loading && users.length <= 0 && <EmptyResponse />}
      </Card>
    </div>
  );
};

export default UsersList;
