import React, { createContext, useEffect, useReducer, useState } from "react";
import { getAppSettingDetails } from "../endpoints/AppsettingsEndpoints";
import { MenuType } from "../Enums";

const JournalContext = createContext({
  appIsReady: "",
  journalData: [],
  dispatchJournalData: () => {},
  setAppIsReady: () => {},
  setJournalCode: () => {},
  setJournalName: () => {},
  journalCode: "",
  journalName: "",
  journalDataMenu2: [],
  dispatchJournalDataMenu2: () => {},
  bannerImages: [],
});

function journalDataDispatcher(state, action) {
  if (action.type === "INITIAL_STATE") {
    return action.data;
  }
  if (action.type === "EDIT_FIELD") {
    const findEditedField = state.find((el) => el.id === action.id);
    if (findEditedField) {
      const indexOfEditedField = state.indexOf(findEditedField);
      const editedObj = state[indexOfEditedField];
      editedObj.value = action.value;
      return [...state];
    }
  }
  if (action.type === "TOGGLE_ACTIVE_FIELD") {
    const findEditedField = state.find((el) => el.id === action.id);
    if (findEditedField) {
      const indexOfEditedField = state.indexOf(findEditedField);
      const editedObj = state[indexOfEditedField];
      editedObj.isActive = !editedObj.isActive;
      return [...state];
    }
  }

  return state;
}

function journalDataDispatcher2(state, action) {
  if (action.type === "INITIAL_STATE") {
    return action.data;
  }
  if (action.type === "EDIT_FIELD") {
    const findEditedField = state.find((el) => el.id === action.id);
    if (findEditedField) {
      const indexOfEditedField = state.indexOf(findEditedField);
      const editedObj = state[indexOfEditedField];
      editedObj.value = action.value;
      return [...state];
    }
  }
  if (action.type === "TOGGLE_ACTIVE_FIELD") {
    const findEditedField = state.find((el) => el.id === action.id);
    if (findEditedField) {
      const indexOfEditedField = state.indexOf(findEditedField);
      const editedObj = state[indexOfEditedField];
      editedObj.isActive = !editedObj.isActive;
      return [...state];
    }
  }
  return state;
}

function JournalContextProvider({ children }) {
  const [journalData, dispatchJournalData] = useReducer(
    journalDataDispatcher,
    []
  );
  const [journalDataMenu2, dispatchJournalDataMenu2] = useReducer(
    journalDataDispatcher2,
    []
  );
  const [journalName, setJournalName] = useState("");
  const [journalCode, setJournalCode] = useState("");
  const [bannerImages, setBannerImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    if (!appIsReady) return;

    setIsLoading(true);
    getAppSettingDetails(MenuType.JournalData)
      .then((res) => {
        dispatchJournalData({
          type: "INITIAL_STATE",
          data: res.data.appSettings,
        });
        setJournalCode(res.data.code);
        setJournalName(res.data.name);
        setBannerImages(res.data.bannerImages);
      })
      .finally(() => setIsLoading(false))
      .catch((err) => {
        console.log(err);
      });
  }, [appIsReady]);

  return (
    <JournalContext.Provider
      value={{
        isLoading: isLoading,
        setAppIsReady,
        journalData,
        dispatchJournalData,
        setJournalCode,
        setJournalName,
        journalCode,
        journalName,
        journalDataMenu2,
        dispatchJournalDataMenu2,
        bannerImages,
      }}
    >
      {children}
    </JournalContext.Provider>
  );
}
export { JournalContext };
export default JournalContextProvider;
