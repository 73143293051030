import React, { useState } from "react";

function Input({ inputProps, label, onChange, errorMessage, value }) {
  const [focus, setFocus] = useState(false);
  return (
    <div className="inputWrapper w-100">
      {label && (
        <label htmlFor={inputProps.id}>
          {label} {inputProps.required && <i style={{ color: "red" }}>*</i>}
        </label>
      )}
      <input
        className="form-control mt-1"
        {...inputProps}
        value={value || ""}
        autoComplete="off"
        onChange={(e) => {
          onChange(e);
        }}
        onBlur={() => {
          setFocus(true);
        }}
        focus={focus.toString()}
      />
      <span className="inputError">{errorMessage}</span>
    </div>
  );
}

export default Input;
