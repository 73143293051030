import "./App.css";
import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import EditJournal from "./pages/EditJournal";
import AddVolume from "./pages/AddVolume";
import VolumesList from "./pages/VolumesList";
import AddEditor from "./pages/AddEditor";
import EditorsList from "./pages/EditorsList";
import ChiefEditorsList from "./pages/ChiefEditorsList";
import AddUser from "./pages/AddUser";
import UsersList from "./pages/UsersList";
import Login from "./pages/Login";
import LayoutWithoutSidebar from "./Layouts/LayoutWithoutSidebar";
import LayoutWithSidebar from "./Layouts/LayoutWithSidebar";
import AddSection from "./pages/AddSection";
import SectionsList from "./pages/SectionsList";
import { createGlobalStyle } from "styled-components";
import { journalColor } from "./JournalSettings";
import { UserRole, UserRoleType } from "./Enums";
import AddIssue from "./pages/AddIssue";
import IssuesList from "./pages/IssuesList";
import EditSmtp from "./pages/EditSmtp";
import AddCountry from "./pages/AddCountry";
import CountriesList from "./pages/CountriesList";
import JournalContextProvider from "./store/JournalContext";
import SessionExpiredModal from "./components/Modals/SessionExpiredModal";
import { Endpoints } from "./endpoints/Instance";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AddArticleType from "./pages/AddArticleType";
import ArticleTypesList from "./pages/ArticleTypesList";
import ErrorPage from "./pages/ErrorPage";
import ManageAppSettings from "./pages/ManageAppSettings/ManageAppSettings";
import AddNews from "./pages/AddNews";
import NewsList from "./pages/NewsList";
import CoEditorsInChiefList from "./pages/CoEditorsInChiefList";
import AssociateEditorsList from "./pages/AssociateEditorsList";
import TechnicalEditorsList from "./pages/TechnicalEditorsList";
import Secretariatlist from "./pages/Secretariatlist";
import Reviewerslist from "./pages/Reviewerslist";
import Reviewersboardlist from "./pages/Reviewersboardlist";
import SettingsContextProvider, {
  SettingsContext,
} from "./store/SettingsContext";

const GlobalStyles = createGlobalStyle`
:root {
  --main800:rgba(${journalColor}, 1);
  --main01:rgba(${journalColor}, 0.1);
  --main05:rgba(${journalColor}, 0.5);
  --main008:rgba(${journalColor}, 0.04);
  --mainLighterAccent: #0070a8;
  --backgroundColorLogin: #f7f3ec;
  --inputBorderColor: #;
  --inputBorderColorFocus: rgba(87, 98, 127, 0.7);
  --black: #000000;
  --grayish: #dadada;
  --mainNavHover: white;
  --paddingLeftRight: 5%;
  --footerBackground: #333;
  --textColor: #1a1a1a;
  --textMuted: #a1a1a1;
}`;

function App() {
  const navigate = useNavigate();
  const [isAppReady, setIsAppReady] = useState(false);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token && !location.pathname.includes("/auth/reset-password")) {
      navigate("/auth/login");
      setIsAppReady(true);
      return;
    }
    setIsAppReady(true);
  }, []);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [location]);

  const handleSessionExpiredModalConfirm = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setShowSessionExpiredModal(false);
    navigate("/auth/login");
  };

  return (
    <>
      <GlobalStyles />
      <Endpoints setShowSessionExpiredModal={setShowSessionExpiredModal} />
      <ToastContainer />
      <SessionExpiredModal
        show={showSessionExpiredModal}
        onConfirm={handleSessionExpiredModalConfirm}
      />
      {isAppReady && (
        <JournalContextProvider>
          <SettingsContextProvider>
            <Routes>
              <Route
                path="/auth/login"
                element={
                  <LayoutWithoutSidebar>
                    <Login />
                  </LayoutWithoutSidebar>
                }
              />
              <Route
                path="/auth/forgot-password"
                element={
                  <LayoutWithoutSidebar>
                    <ForgotPassword />
                  </LayoutWithoutSidebar>
                }
              />
              <Route
                path="/auth/reset-password/:token"
                element={
                  <LayoutWithoutSidebar>
                    <ResetPassword />
                  </LayoutWithoutSidebar>
                }
              />
              <Route
                path="/"
                element={
                  <LayoutWithSidebar>
                    <Dashboard />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/editjournal"
                element={
                  <LayoutWithSidebar>
                    <EditJournal />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/addvolume"
                element={
                  <LayoutWithSidebar>
                    <AddVolume />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/addvolume/:volumeId"
                element={
                  <LayoutWithSidebar>
                    <AddVolume />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/volumeslist"
                element={
                  <LayoutWithSidebar>
                    <VolumesList />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/addeditor"
                element={
                  <LayoutWithSidebar>
                    <AddEditor />
                  </LayoutWithSidebar>
                }
              />

              <Route
                path="/addeditor/:userId"
                element={
                  <LayoutWithSidebar>
                    <AddEditor />
                  </LayoutWithSidebar>
                }
              />

              <Route
                path="/editorslist"
                element={
                  <LayoutWithSidebar>
                    <EditorsList
                      userRole={[UserRole.Editor]}
                      userRoleType={UserRoleType.Editor}
                    />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/editorsinchieflist"
                element={
                  <LayoutWithSidebar>
                    <ChiefEditorsList
                      userRole={[UserRole.ChiefEditor]}
                      userRoleType={UserRoleType.EditorInChief}
                    />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/coeditorsinchieflist"
                element={
                  <LayoutWithSidebar>
                    <CoEditorsInChiefList
                      userRole={[UserRole.ChiefEditor]}
                      userRoleType={UserRoleType.CoEditorInChief}
                    />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/associateeditorslist"
                element={
                  <LayoutWithSidebar>
                    <AssociateEditorsList
                      userRole={[UserRole.Editor]}
                      userRoleType={UserRoleType.AssociateEditor}
                    />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/technicaleditorslist"
                element={
                  <LayoutWithSidebar>
                    <TechnicalEditorsList
                      userRole={[UserRole.Editor]}
                      userRoleType={UserRoleType.TechnicalEditor}
                    />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/secretariatlist"
                element={
                  <LayoutWithSidebar>
                    <Secretariatlist
                      userRole={[UserRole.Editor]}
                      userRoleType={UserRoleType.Secretariat}
                    />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/reviewerslist"
                element={
                  <LayoutWithSidebar>
                    <Reviewerslist
                      userRole={[UserRole.Reviewer]}
                      userRoleType={UserRoleType.Reviewer}
                    />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/reviewersboardlist"
                element={
                  <LayoutWithSidebar>
                    <Reviewersboardlist
                      userRole={[UserRole.ReviewerBoard]}
                      userRoleType={UserRoleType.ReviewersBoard}
                    />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/adduser/:userId"
                element={
                  <LayoutWithSidebar>
                    <AddUser />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/adduser"
                element={
                  <LayoutWithSidebar>
                    <AddUser />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/userslist"
                element={
                  <LayoutWithSidebar>
                    <UsersList userRole={[UserRole.Admin]} />
                  </LayoutWithSidebar>
                }
              />

              <Route
                path="/addsection"
                element={
                  <LayoutWithSidebar>
                    <AddSection />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/addsection/:sectionId"
                element={
                  <LayoutWithSidebar>
                    <AddSection />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/sectionslist"
                element={
                  <LayoutWithSidebar>
                    <SectionsList />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/addissue"
                element={
                  <LayoutWithSidebar>
                    <AddIssue />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/addissue/:issueId"
                element={
                  <LayoutWithSidebar>
                    <AddIssue />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/issueslist"
                element={
                  <LayoutWithSidebar>
                    <IssuesList />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/editsmtp"
                element={
                  <LayoutWithSidebar>
                    <EditSmtp />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/addcountry"
                element={
                  <LayoutWithSidebar>
                    <AddCountry />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/addcountry/:countryId"
                element={
                  <LayoutWithSidebar>
                    <AddCountry />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/countrieslist"
                element={
                  <LayoutWithSidebar>
                    <CountriesList />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/addarticletype"
                element={
                  <LayoutWithSidebar>
                    <AddArticleType />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/addarticletype/:id"
                element={
                  <LayoutWithSidebar>
                    <AddArticleType />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/articletypeslist"
                element={
                  <LayoutWithSidebar>
                    <ArticleTypesList />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/settings"
                element={
                  <LayoutWithSidebar>
                    <ManageAppSettings />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/addnews"
                element={
                  <LayoutWithSidebar>
                    <AddNews />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/addnews/:id"
                element={
                  <LayoutWithSidebar>
                    <AddNews />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="/newslist"
                element={
                  <LayoutWithSidebar>
                    <NewsList />
                  </LayoutWithSidebar>
                }
              />
              <Route
                path="*"
                element={
                  <LayoutWithSidebar>
                    <ErrorPage />
                  </LayoutWithSidebar>
                }
              />
            </Routes>
          </SettingsContextProvider>
        </JournalContextProvider>
      )}
    </>
  );
}

export default App;
