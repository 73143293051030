import React, { useEffect, useState } from "react";
import Input from "../components/Inputs/Input";
import Card from "../components/Card/Card";
import ButtonWithLoader from "../components/ButtonWithLoader/ButtonWithLoader";
import ReactQuill from "react-quill";
import { addOrUpdateNews, getNewsById } from "../endpoints/News";
import { NewsStatus } from "../Enums";
import { errorToast, successToast } from "../HelperFunctions/HelperFunctions";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import RadioButtons from "../components/Inputs/RadioButtons";

const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ color: [] }, { background: [] }],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  [{ align: [] }, { direction: "rtl" }],
  ["link", "image", "video"],
  ["code-block", "blockquote"],
];

const AddNews = () => {
  const [values, setValues] = useState({
    title: "",
    content: "",
    statusId: NewsStatus.Published,
  });

  const [isGettingNews, setIsGettingNews] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [initialStatus, setInitialStatus] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setIsGettingNews(true);
      getNewsById(id)
        .then((res) => {
          setValues({
            title: res.data.title,
            id: id,
            content: res.data.content,
            statusId: res.data.statusId,
          });
          setInitialStatus(res.data.statusId);
        })
        .finally(() => setIsGettingNews(false))
        .catch((error) => {
          console.error(error);
        });
    } else {
      setValues({ title: "", content: "", statusId: NewsStatus.Published });
    }
  }, [id]);

  const handleSave = (e) => {
    e.preventDefault();
    if (values.content.trim() !== "" && values.title !== "") {
      setIsSaving(true);
      addOrUpdateNews(values)
        .then((res) => {
          if (res.data.success) {
            successToast(res.data.message);
            setInitialStatus(values.statusId);
          } else {
            errorToast(res.data.message);
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      errorToast("News fields must have a value!");
    }
  };

  function onChange(e) {
    const name = e.target.name || e.target.title;
    let value = e.target.value;
    if (name === "statusId") value = +value;
    setValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  return (
    <div className="main-content">
      <Card>
        <h3>{id ? "Edit" : "Add"} News</h3>
        {isGettingNews && (
          <LoadingSpinner
            color={"var(--main800)"}
            height={"30px"}
            width={"30px"}
          />
        )}
        {!isGettingNews && (
          <div className="col-md-6">
            <div className="newsStatus" style={{ fontSize: "12px" }}>
              {(() => {
                if (id) {
                  if (initialStatus === NewsStatus.Published) {
                    return <span>This news is published</span>;
                  } else if (initialStatus === NewsStatus.SaveDraft) {
                    return <span>This news is saved as draft</span>;
                  } else if (initialStatus === NewsStatus.Passive) {
                    return <span>This news is passive</span>;
                  }
                }
                return null;
              })()}
            </div>
            <div className="row mt-2">
              <div className="col-12 mb-3">
                <Input
                  inputProps={{
                    type: "text",
                    name: "title",
                    id: "title",
                    required: true,
                    maxLength: "150",
                  }}
                  value={values.title}
                  onChange={(e) =>
                    setValues((prev) => ({ ...prev, title: e.target.value }))
                  }
                  label={"Title"}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 mb-3">
                <label className="mb-1">
                  Content <i style={{ color: "red" }}>*</i>
                </label>
                <ReactQuill
                  required={true}
                  value={values.content}
                  onChange={(e) =>
                    setValues((prev) => ({ ...prev, content: e }))
                  }
                  modules={{ toolbar: { container: toolbarOptions } }}
                  scrollingContainer="#quill-scrolling-container"
                />
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <label>
                Status<i style={{ color: "red" }}>*</i>
              </label>
              <div className="p-0 my-1 mx-0 d-flex" style={{ gap: "15px" }}>
                <RadioButtons
                  label={"Publish"}
                  name={"statusId"}
                  value={NewsStatus.Published}
                  id={"publish"}
                  checked={values.statusId === NewsStatus.Published}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
                <RadioButtons
                  label={"Draft"}
                  name={"statusId"}
                  value={NewsStatus.SaveDraft}
                  id={"draft"}
                  checked={values.statusId === NewsStatus.SaveDraft}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
                <RadioButtons
                  label={"Passive"}
                  name={"statusId"}
                  value={NewsStatus.Passive}
                  id={"passive"}
                  checked={values.statusId === NewsStatus.Passive}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end mt-2">
              <div
                className="btn me-2 d-flex align-items-center"
                onClick={() => navigate("/newslist")}
              >
                Cancel
              </div>
              <ButtonWithLoader isLoading={isSaving} onClick={handleSave}>
                {id ? "Edit" : "Add"} News
              </ButtonWithLoader>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default AddNews;
