import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import ReactFullscreen from "react-easyfullscreen";
import { JournalContext } from "../store/JournalContext";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import "./Layout.css";

const LayoutWithSidebar = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { isLoading } = useContext(JournalContext);

  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }

  return (
    <ReactFullscreen>
      {({ onToggle }) => (
        <div className="d-flex h-auto">
          <Sidebar
            showSidebar={showSidebar}
            onClickOutside={() => {
              setShowSidebar(true);
            }}
          />
          <div className="flex-grow-1 main-container">
            <Header toggleSidebar={toggleSidebar} onToggle={onToggle} />
            {!isLoading ? (
              <div> {children}</div>
            ) : (
              <LoadingSpinner
                color={"var(--main800)"}
                height={"30px"}
                width={"30px"}
              />
            )}
          </div>
        </div>
      )}
    </ReactFullscreen>
  );
};

export default LayoutWithSidebar;
