import React, { useState, useEffect } from "react";
import Card from "../components/Card/Card";
import {
  deleteIssue,
  getJournalIssuesList,
  changeIssueStatus,
} from "../endpoints/JournalsEndpoints";
import DeleteModal from "../components/Modals/DeleteModal";
import {
  errorToast,
  statusName,
  successToast,
} from "../HelperFunctions/HelperFunctions";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import EmptyResponse from "../components/EmptyResponse/EmptyResponse";
import Table from "../components/Table/Table";

const IssuesList = () => {
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIssueId, setSelectedIssueId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    getJournalIssuesList()
      .then((res) => {
        const copiedArray = res.map((obj) => ({
          name: obj.name,
          sections: obj.journalIssueSections
            .map((section) => section.journalSection.name)
            .join(", "),
          statusId: obj.statusId,
          id: obj.id,
        }));
        setIssues(copiedArray);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleDeleteIssue = (issueId) => {
    deleteIssue(issueId).then((res) => {
      if (res.data.success) {
        successToast(res.data.message);
        const newIssues = issues.filter((obj) => !issueId.includes(obj.id));
        setIssues(newIssues);
        toggleModal();
      } else {
        errorToast(res.data.message);
      }
      toggleModal();
    });
  };

  const toggleModal = (issueId) => {
    setSelectedIssueId(issueId);
    if (showModal) {
      setShowModal(false);
      document.body.style.overflow = "unset";
    } else {
      setShowModal(true);
      document.body.style.overflow = "hidden";
    }
  };

  const tableHeaders = [
    { value: "Name" },
    { value: "Sections" },
    { value: "Status" },
  ];

  return (
    <div className="main-content">
      <Card>
        <h3>Issues List</h3>
        <DeleteModal
          show={showModal}
          onCancel={toggleModal}
          onConfirm={() => handleDeleteIssue(selectedIssueId)}
          name={"issue"}
        />
        {loading && (
          <LoadingSpinner
            color={"var(--main800)"}
            height={"30px"}
            width={"30px"}
          />
        )}
        {!loading && issues.length > 0 && (
          <Table
            tableHeaders={tableHeaders}
            tableData={issues}
            editNavigateLink={"/addissue"}
            toggleModal={toggleModal}
            changeStatus={changeIssueStatus}
            setValues={setIssues}
          />
        )}
        {!loading && issues.length <= 0 && <EmptyResponse />}
      </Card>
    </div>
  );
};

export default IssuesList;
