import { GUID } from "../JournalSettings";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import instance from "./Instance";

async function login(email, password) {
  const body = {
    journalId: GUID,
    role: 1,
    email: email,
    password: password,
  };
  return instance.post("/Login/Login", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function addUser(otherParams) {
  const body = {
    journalId: GUID,
    isFromAdmin: true,
    ...otherParams,
  };
  return instance.post("Login/SignUp", body).then((response) => {
    return Promise.resolve(response);
  });
}

export { login, addUser };
