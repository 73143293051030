import { GUID } from "../JournalSettings";
import instance from "./Instance";

async function addCountry(countryName) {
  const body = {
    journalId: GUID,
    name: countryName,
  };
  return instance.post("Country/AddorUpdateCountry", body).then((result) => {
    return Promise.resolve(result);
  });
}

async function getCountryById(id) {
  return instance
    .get(`/Country/GetCountryById?countryId=${id}`)
    .then((response) => {
      return Promise.resolve(response.data);
    });
}

async function updateCountry(countryId, countryName) {
  const body = {
    journalId: GUID,
    id: countryId,
    name: countryName,
  };
  return instance.post("Country/AddorUpdateCountry", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function getCountriesList() {
  return instance
    .get(`/Country/GetCountriesList?journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function deleteCountry(countryId) {
  return instance
    .delete(`/Country/DeleteCountry?id=${countryId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

export {
  addCountry,
  getCountryById,
  updateCountry,
  getCountriesList,
  deleteCountry,
};
