import React from "react";
import "./LoadingSpinner.css";

const LoadingSpinner = ({ color, height, width }) => {
  return (
    <div className="spinner-container">
      <div
        className="loading-spinner"
        style={{
          borderTop: `4px solid ${color}`,
          height: `${height}`,
          width: `${width}`,
        }}
      ></div>
    </div>
  );
};

export default LoadingSpinner;
