import React from "react";
import "./Modals.css";

const SessionExpiredModal = ({ show, onConfirm }) => {
  return (
    <div className={`session-expired-modal ${show ? "show" : ""}`}>
      <div className="modal-content text-start">
        <h5>Session expired</h5>
        <p className="mb-4 mt-1">Your session has expired!</p>
        <div className="text-end">
          <button className="btn btn-danger" onClick={onConfirm}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
export default SessionExpiredModal;
