import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { TbEdit } from "react-icons/tb";
import { TiTick } from "react-icons/ti";
import { MdAddCircleOutline } from "react-icons/md";

const MultipleInputs = ({
  label,
  id,
  type = "text",
  name,
  items = [],
  handleAddItems,
  handleDelete,
  editItem = "",
  setEditItem,
}) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [inputValue, setInputValue] = useState("");

  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter") {
  //     const newValue = e.target.value.trim();
  //     if (newValue !== "") {
  //       handleAddItems([...items, newValue]);
  //       setInputValue("");
  //     }
  //   }
  // };

  const handleClick = () => {
    if (inputValue !== "") {
      handleAddItems([...items, inputValue]);
      setInputValue("");
    }
  };

  const handleEdit = (index, value) => {
    setEditingIndex(index);
    setEditItem(value);
  };

  const handleSave = (index) => {
    const updatedValues = [...items];
    if (editItem !== "") {
      updatedValues[index] = editItem;
      const filtered = updatedValues.filter((elm) => elm);
      handleAddItems(filtered);
      setEditingIndex(null);
      setEditItem(null);
    } else {
      updatedValues[index] = "";
    }
  };

  return (
    <>
      <label className="mb-1" htmlFor={id}>
        {label}
      </label>
      <div className="d-flex" style={{ gap: "10px" }}>
        <input
          name={name}
          id={id}
          type={type}
          value={inputValue}
          className="form-control"
          onChange={(e) => setInputValue(e.target.value)}
          maxLength={350}
          //onKeyDown={handleKeyDown}
        />

        <div className="btn" onClick={handleClick}>
          <MdAddCircleOutline size="20px" color="var(--main800)" />
          Add Topic
        </div>
      </div>
      <ul
        className="list-group list-group-flush"
        style={{ height: "min-content" }}
      >
        {Array.isArray(items) &&
          items.map((value, index) => (
            <li key={index} className="mt-2 list-group-item col-12 px-0">
              {editingIndex === index ? (
                <div
                  className="d-flex justify-content-between w-100"
                  style={{ gap: "10px" }}
                >
                  <input
                    name={name}
                    id={id}
                    type={type}
                    value={editItem}
                    className="form-control"
                    onChange={(e) => setEditItem(e.target.value)}
                    maxLength={350}
                    //  onKeyDown={handleKeyDown}
                  />
                  <div className="btn" onClick={() => handleSave(index)}>
                    <TiTick color="green" size="20px" />
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-between align-items-center">
                  <p className="ms-3 mb-0">{value}</p>
                  <div className="d-flex">
                    <div
                      className="btn btn-link text-dark p-0 mx-2 my-0"
                      onClick={() => handleEdit(index, value)}
                    >
                      <TbEdit color="var(--main800)" />
                    </div>
                    <div
                      className="btn btn-link p-0 text-danger"
                      onClick={() => handleDelete(index)}
                    >
                      <FaTimes />
                    </div>
                  </div>
                </div>
              )}
            </li>
          ))}
      </ul>
    </>
  );
};

export default MultipleInputs;
