import React from "react";
import { CorrespondingAuthor } from "../../Enums";

const CorrespondingAuthorSetting = ({
  journalDataMenu2,
  correspondingAuthor,
  handleChange,
}) => {
  return (
    <div className="d-flex flex-wrap">
      <label
        className="mb-1 col-6 align-self-center"
        style={{ cursor: "unset" }}
      >
        Corresponding Author:
      </label>
      <div
        className="col-6 d-flex flex-wrap flex-column"
        style={{ gap: "5px" }}
      >
        <label
          className="col-md-6 w-100 d-flex align-items-center"
          title="Only the user that is logged in can be corresponding author for articles"
        >
          <input
            className="me-1"
            type="radio"
            name="correspondingAuthor"
            value={CorrespondingAuthor.OnlyLoggedInUser}
            id={CorrespondingAuthor.OnlyLoggedInUser}
            checked={
              JSON.parse(correspondingAuthor?.value) ===
              CorrespondingAuthor.OnlyLoggedInUser
            }
            onChange={() => {
              const correspondingAuthorObj = journalDataMenu2.find(
                (el) => el.key === "Allow Multi Corresponding Author"
              );
              handleChange(
                JSON.stringify(CorrespondingAuthor.OnlyLoggedInUser),
                correspondingAuthorObj.id
              );
            }}
          />
          Only Logged In Author
        </label>
        <label
          className="col-md-6 w-100 d-flex align-items-center"
          title="Only one user can be corresponding author for articles"
        >
          <input
            className="me-1"
            type="radio"
            name="correspondingAuthor"
            value={CorrespondingAuthor.AnyUser}
            id={CorrespondingAuthor.AnyUser}
            checked={
              JSON.parse(correspondingAuthor?.value) ===
              CorrespondingAuthor.AnyUser
            }
            onChange={() => {
              const correspondingAuthorObj = journalDataMenu2.find(
                (el) => el.key === "Allow Multi Corresponding Author"
              );
              handleChange(
                JSON.stringify(CorrespondingAuthor.AnyUser),
                correspondingAuthorObj.id
              );
            }}
          />
          Any Other Author
        </label>
        <label
          className="col-md-6 w-100 d-flex align-items-center"
          title="More than one user can be corresponding authors for articles"
        >
          <input
            className="me-1"
            type="radio"
            name="correspondingAuthor"
            value={CorrespondingAuthor.MultipleUser}
            id={CorrespondingAuthor.MultipleUser}
            checked={
              JSON.parse(correspondingAuthor?.value) ===
              CorrespondingAuthor.MultipleUser
            }
            onChange={() => {
              const correspondingAuthorObj = journalDataMenu2.find(
                (el) => el.key === "Allow Multi Corresponding Author"
              );
              handleChange(
                JSON.stringify(CorrespondingAuthor.MultipleUser),
                correspondingAuthorObj.id
              );
            }}
          />
          Multiple Authors
        </label>
      </div>
    </div>
  );
};

export default CorrespondingAuthorSetting;
