import { GUID } from "../JournalSettings";
import "react-toastify/dist/ReactToastify.css";
import instance from "./Instance";
import { Status } from "../Enums";

async function getJournalUsers(roles, roleType) {
  const body = {
    journalId: GUID,
    roles: roles,
    roleType: roleType,
  };
  return instance.post("/User/GetUsersByRoleList", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function deleteUser(userId) {
  return instance.delete(`/User/DeleteUser?id=${userId}`).then((response) => {
    return Promise.resolve(response);
  });
}

async function getUserById(id) {
  return instance
    .get(`/User/GetUserById?Id=${id}&journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response.data);
    });
}

async function changeUserStatus(id, statusId) {
  let status = statusId === Status.Active ? Status.Passive : Status.Active;
  const body = {
    id: id,
    status: status,
  };
  return instance.post("User/ChangeUserStatus", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function updateUser(userId, otherParams) {
  const body = {
    journalId: GUID,
    id: userId,
    isFromAdmin: true,
    status: 1,
    ...otherParams,
  };
  return instance.post("User/EditUser", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function forgotPassword(email) {
  return instance
    .post(`/User/ForgotPassword?email=${email}`)
    .then((res) => Promise.resolve(res));
}

async function resetPassword(body) {
  return instance
    .post(`User/ResetPassword?`, body)
    .then((res) => Promise.resolve(res));
}

export {
  getJournalUsers,
  getUserById,
  deleteUser,
  updateUser,
  changeUserStatus,
  forgotPassword,
  resetPassword,
};
