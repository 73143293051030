import React, { useState } from "react";
import Input from "../components/Inputs/Input";
import Card from "../components/Card/Card";
import { forgotPassword } from "../endpoints/UserEndpoints";
import { errorToast, successToast } from "../HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../components/ButtonWithLoader/ButtonWithLoader";

const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    forgotPassword(email)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  return (
    <div className="w-50 m-auto">
      <Card>
        <form onSubmit={handleSubmit} className="forgetPasswordForm">
          <div className="m-auto w-75">
            <h4 className="text-center">Forgot Password</h4>
            <p>
              Enter the email address associated with your account and we will
              send you a link to reset password
            </p>
            <div className="mb-3">
              <Input
                inputProps={{
                  name: "email",
                  type: "text",
                  required: true,
                }}
                value={email}
                label={"Email"}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="d-flex flex-column align-items-center mb-5">
              <ButtonWithLoader
                isLoading={isRequestBeingMade}
                classNames={["login-btn"]}
              >
                Continue
              </ButtonWithLoader>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default ForgotPassword;
