import React, { useEffect, useState } from "react";
import ButtonWithLoader from "../components/ButtonWithLoader/ButtonWithLoader";
import Input from "../components/Inputs/Input";
import Card from "../components/Card/Card";
import { useNavigate, useParams } from "react-router-dom";
import {
  addOrUpdateArticleType,
  getArticleTypeById,
} from "../endpoints/JournalsEndpoints";
import { successToast, errorToast } from "../HelperFunctions/HelperFunctions";

const AddArticleType = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [values, setValues] = useState({
    name: "",
  });
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  useEffect(() => {
    if (id) {
      getArticleTypeById(id)
        .then((res) => {
          setValues({ id: res.id, name: res.name });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setValues({ name: "" });
    }
  }, [id]);

  const handleSave = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    addOrUpdateArticleType(values)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          navigate("/articletypeslist");
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    addOrUpdateArticleType(values)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          navigate("/articletypeslist");
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  function onChange(e) {
    const name = e.target.name || e.target.title;
    let value = e.target.value;

    setValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  return (
    <div className="main-content">
      <Card>
        <h3>{id ? "Edit" : "Add"} Article Type</h3>
        <form onSubmit={id ? handleEdit : handleSave}>
          <div className="row mt-2">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "name",
                  id: "name",
                  required: true,
                  maxLength: "350",
                }}
                value={values.name}
                onChange={onChange}
                label={"Name"}
                errorMessage={"Field Cannot be Empty"}
              />
              <div className="d-flex justify-content-end mt-2">
                <div
                  className="btn me-2 d-flex align-items-center"
                  onClick={() => navigate("/articletypeslist")}
                >
                  Cancel
                </div>
                <ButtonWithLoader isLoading={isRequestBeingMade}>
                  {id ? "Edit" : "Add"} Article Type
                </ButtonWithLoader>
              </div>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default AddArticleType;
