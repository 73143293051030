import React, { useEffect, useState } from "react";
import DeleteModal from "../components/Modals/DeleteModal";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import Card from "../components/Card/Card";
import {
  changeArticleTypeStatus,
  deleteArticleType,
  getArticleTypeList,
} from "../endpoints/JournalsEndpoints";
import EmptyResponse from "../components/EmptyResponse/EmptyResponse";
import {
  errorToast,
  statusName,
  successToast,
} from "../HelperFunctions/HelperFunctions";
import Table from "../components/Table/Table";

const ArticleTypesList = () => {
  const [articleTypes, setArticleTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedArticleType, setSelectedArticleType] = useState();

  useEffect(() => {
    setLoading(true);
    getArticleTypeList()
      .then((res) => {
        const copiedArray = res.data.articleTypes.map((obj) => ({
          name: obj.name,
          statusId: obj.statusId,
          id: obj.id,
        }));
        setArticleTypes(copiedArray);
      })
      .finally(() => setLoading(false));
  }, []);

  const toggleModal = (articleTypeId) => {
    setSelectedArticleType(articleTypeId);
    if (showModal) {
      setShowModal(false);
      document.body.style.overflow = "unset";
    } else {
      setShowModal(true);
      document.body.style.overflow = "hidden";
    }
  };

  const handleDeleteArticleType = (articleTypeId) => {
    deleteArticleType(articleTypeId).then((res) => {
      if (res.data.success) {
        successToast(res.data.message);
        const newArticleTypes = articleTypes.filter(
          (obj) => !articleTypeId.includes(obj.id)
        );
        setArticleTypes(newArticleTypes);
        toggleModal();
      } else {
        errorToast(res.data.message);
      }
      toggleModal();
    });
  };

  const tableHeaders = [{ value: "Title" }, { value: "Status" }];

  return (
    <div className="main-content">
      <Card>
        <h3>Article Types List</h3>
        <DeleteModal
          show={showModal}
          onCancel={toggleModal}
          onConfirm={() => handleDeleteArticleType(selectedArticleType)}
          name={"article type"}
        />
        {loading && (
          <LoadingSpinner
            color={"var(--main800)"}
            height={"30px"}
            width={"30px"}
          />
        )}
        {!loading && articleTypes.length > 0 && (
          <Table
            tableHeaders={tableHeaders}
            tableData={articleTypes}
            editNavigateLink={"/addarticletype"}
            toggleModal={toggleModal}
            changeStatus={changeArticleTypeStatus}
            setValues={setArticleTypes}
          />
        )}
        {!loading && articleTypes.length <= 0 && <EmptyResponse />}
      </Card>
    </div>
  );
};

export default ArticleTypesList;
