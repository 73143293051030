import React, { useState, useEffect } from "react";
import Input from "../components/Inputs/Input";
import Card from "../components/Card/Card";
import {
  getSmtpConfig,
  updateSmtpConfig,
} from "../endpoints/SmtpConfigEndpoints";
import { errorToast, successToast } from "../HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../components/ButtonWithLoader/ButtonWithLoader";

const EditSmtp = () => {
  const [smtpId, setSmtpId] = useState("");
  const [smtpUser, setSmtpUser] = useState("");
  const [smtpHost, setSmtpHost] = useState("");
  const [smtpPassword, setSmtpPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [smtpPort, setSmtpPort] = useState("");
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  useEffect(() => {
    getSmtpConfig().then((res) => {
      setSmtpUser(res.data.smtpUser || "");
      setSmtpHost(res.data.smtpHost || "");
      setSmtpPassword(res.data.smtpPassword || "");
      setDisplayName(res.data.displayName || "");
      setSmtpPort(res.data.smtpPort || "");
      setSmtpId(res.data.id);
    });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    updateSmtpConfig(
      smtpId,
      smtpUser,
      smtpHost,
      smtpPassword,
      displayName,
      smtpPort
    )
      .then((res) => {
        if (res.data.success) {
          setSmtpId(res.data.id);
          successToast(res.data.message);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  return (
    <div className="main-content">
      <Card>
        <h3>Edit SMTP</h3>
        <form onSubmit={handleSave}>
          <div className="row mt-2">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "smtpUser",
                  id: "smtpUser",
                  required: true,
                  maxLength: "350",
                }}
                value={smtpUser}
                onChange={(e) => setSmtpUser(e.target.value)}
                label={"SMTP User"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "smtpHost",
                  id: "smtpHost",
                  required: true,
                  maxLength: "350",
                }}
                value={smtpHost}
                onChange={(e) => setSmtpHost(e.target.value)}
                label={"SMTP Host"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "password",
                  name: "smtpPassword",
                  id: "smtpPassword",
                  required: true,
                  maxLength: "350",
                }}
                value={smtpPassword}
                onChange={(e) => setSmtpPassword(e.target.value)}
                label={"SMTP Password"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "displayName",
                  id: "displayName",
                  required: true,
                  maxLength: "350",
                }}
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                label={"Display Name"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "number",
                  name: "smtpPort",
                  id: "smtpPort",
                  required: true,
                  max: "2147483647",
                }}
                value={smtpPort}
                onChange={(e) => setSmtpPort(e.target.value)}
                label={"SMTP Port"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <ButtonWithLoader isLoading={isRequestBeingMade}>
              Save Smtp
            </ButtonWithLoader>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EditSmtp;
