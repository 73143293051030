import React, { useEffect, useState } from "react";
import Card from "../components/Card/Card";
import Input from "../components/Inputs/Input";
import { useNavigate, useParams } from "react-router-dom";
import { getUserById, updateUser } from "../endpoints/UserEndpoints";
import { addUser } from "../endpoints/LoginEndpoints";
import { errorToast, successToast } from "../HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../components/ButtonWithLoader/ButtonWithLoader";

function initEditorObject(user = null) {
  const userObject = {
    role: 1,
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    phoneNo: user?.phoneNo || "",
    title: "",
    universityOrOrganization: "",
    department: "",
    addressLine1: "",
    addressLine2: "",
    zipCode: "",
    city: "",
  };
  return userObject;
}

const AddUser = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  const [values, setValues] = useState(initEditorObject());

  useEffect(() => {
    if (userId) {
      getUserById(userId)
        .then((user) => {
          setValues(initEditorObject(user));
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setValues(initEditorObject());
    }
  }, [userId]);

  const handleSave = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    addUser(values)
      .then((res) => {
        if (res.data.success) {
          navigate("/userslist");
          successToast(res.data.message);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  function onChange(e) {
    const name = e.target.name || e.target.title;
    let value = e.target.value;

    setValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  const handleEdit = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    setValues({ ...values, role: 1 });
    updateUser(userId, values)
      .then((res) => {
        if (res.data.success) {
          navigate("/userslist");
          successToast(res.data.message);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  return (
    <div className="main-content">
      <Card>
        <h3>{userId ? "Edit" : "Add"} Admin User</h3>
        <form onSubmit={userId ? handleEdit : handleSave}>
          <div className="row mt-2">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "firstName",
                  id: "firstName",
                  required: true,
                  maxLength: "150",
                }}
                value={values.firstName}
                onChange={onChange}
                label={"First Name"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "lastName",
                  id: "lastName",
                  required: true,
                  maxLength: "150",
                }}
                value={values.lastName}
                onChange={onChange}
                label={"Last Name"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  name: "email",
                  type: "email",
                  required: true,
                  pattern: "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$",
                  maxLength: "80",
                }}
                value={values.email}
                errorMessage={"Invalid Email"}
                label={"Email"}
                onChange={onChange}
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "phoneNo",
                  id: "contactNumber",
                  maxLength: "25",
                }}
                value={values.phoneNo}
                onChange={onChange}
                label={"Contact Number"}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-2">
            <div
              className="btn me-2 d-flex align-items-center"
              onClick={() => navigate("/userslist")}
            >
              Cancel
            </div>
            <ButtonWithLoader isLoading={isRequestBeingMade}>
              {" "}
              {userId ? "Edit" : "Add"} Admin User
            </ButtonWithLoader>
          </div>
        </form>
      </Card>
    </div>
  );
};
export default AddUser;
