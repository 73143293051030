import React, { useState, useEffect } from "react";
import Card from "../components/Card/Card";
import Input from "../components/Inputs/Input";
import MultipleInputs from "../components/Inputs/MultipleInputs";
import { useNavigate, useParams } from "react-router-dom";
import {
  addOrUpdateSection,
  getSectionById,
} from "../endpoints/JournalsEndpoints";
import { errorToast, successToast } from "../HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../components/ButtonWithLoader/ButtonWithLoader";

const AddSection = () => {
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [values, setValues] = useState({
    name: "",
    sectionResearchTopics: [],
  });
  const navigate = useNavigate();
  const { sectionId } = useParams();

  useEffect(() => {
    if (sectionId) {
      getSectionById(sectionId)
        .then((section) => {
          setValues({
            name: section.name,
            sectionResearchTopics: section.sectionResearchTopics?.map(
              (topic) => topic.name
            ),
            id: section.id,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setValues({ name: "", sectionResearchTopics: [] });
    }
  }, [sectionId]);

  const handleSave = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    addOrUpdateSection(values)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          navigate("/sectionslist");
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    if (editValue === null) {
      setIsRequestBeingMade(true);
      addOrUpdateSection(values)
        .then((res) => {
          if (res.data.success) {
            successToast(res.data.message);
            navigate("/sectionslist");
          } else {
            errorToast(res.data.message);
          }
        })
        .finally(() => setIsRequestBeingMade(false));
    } else {
      errorToast("Topic's name should have a value");
    }
  };

  const handleAddTopics = (topics) => {
    setValues({ ...values, sectionResearchTopics: topics });
  };

  const handleDelete = (index) => {
    const topics = values.sectionResearchTopics
      ? values.sectionResearchTopics.slice()
      : [];
    topics.splice(index, 1);
    setValues({ ...values, sectionResearchTopics: topics });
  };

  function onChange(e) {
    const name = e.target.name || e.target.title;
    let value = e.target.value;

    setValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  return (
    <div className="main-content ">
      <Card>
        <h3>{sectionId ? "Edit" : "Add"} Section</h3>

        <div className="col-md-8 mt-2">
          <div className="col-md-10 mb-3">
            <Input
              inputProps={{
                type: "text",
                name: "name",
                id: "name",
                required: true,
                maxLength: "350",
              }}
              value={values.name}
              onChange={onChange}
              label={"Name"}
            />
          </div>
          <div className="col-md-10 mb-3">
            <MultipleInputs
              label={"Topics"}
              id={"topics"}
              name={"sectionResearchTopics"}
              items={values.sectionResearchTopics}
              handleAddItems={handleAddTopics}
              handleDelete={handleDelete}
              setEditItem={setEditValue}
              editItem={editValue}
            />
          </div>
          <div className="col-md-10 d-flex justify-content-end">
            <div className="d-flex justify-content-end mt-2">
              <div
                className="btn me-2 d-flex align-items-center"
                onClick={() => navigate("/sectionslist")}
              >
                Cancel
              </div>
              <ButtonWithLoader
                onClick={sectionId ? handleEdit : handleSave}
                isLoading={isRequestBeingMade}
              >
                {" "}
                {sectionId ? "Edit" : "Save"} Section
              </ButtonWithLoader>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AddSection;
