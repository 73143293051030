const UserRole = {
  Admin: 1,
  Author: 2,
  Editor: 3,
  ChiefEditor: 4,
  Reviewer: 5,
  ReviewerBoard: 6,
};

const ArticleStatus = {
  Pending: 0,
  Submited: 1,
  InProgres: 2,
  InReview: 3,
  Published: 4,
};

const Status = {
  Passive: 0,
  Active: 1,
};

const UserStatus = {
  Active: 1,
  Passive: 2,
  Requested: 3,
  Rejected: 4,
};

const AppSettingType = {
  ReadOnly: 0,
  Textbox: 1,
  Checkbox: 2,
  Button: 3,
  TextboxNumber: 4,
  TextboxCharacter: 5,
  File: 6,
  RadioButton: 7,
  Dropdown: 8,
  Hidden: 9,
};

const MenuType = {
  JournalData: 1,
  SettingsData: 2,
};

const CorrespondingAuthor = {
  OnlyLoggedInUser: 1,
  AnyUser: 2,
  MultipleUser: 3,
};

const NewsStatus = {
  Passive: -1,
  SaveDraft: 1,
  Published: 2,
};

const UserRoleType = {
  Admin: -2,
  Author: -1,
  EditorInChief: 1,
  CoEditorInChief: 2,

  Editor: 3,
  AssociateEditor: 4,
  TechnicalEditor: 5,
  Secretariat: 6,

  Reviewer: 7,
  ReviewersBoard: 8,
};

const RequestType = {
  Portal: 1,
  UiSystem: 2,
};

export {
  UserRole,
  ArticleStatus,
  Status,
  UserStatus,
  AppSettingType,
  MenuType,
  CorrespondingAuthor,
  NewsStatus,
  UserRoleType,
  RequestType,
};
