import { GUID } from "../JournalSettings";
import "react-toastify/dist/ReactToastify.css";
import instance from "./Instance";
import { Status } from "../Enums";

async function deleteIssue(issueId) {
  return instance
    .delete(`/Journals/DeleteIssue?id=${issueId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function getIssueById(id) {
  return instance.get(`/Journals/GetIssueById?Id=${id}`).then((response) => {
    return Promise.resolve(response.data);
  });
}

async function getJournalIssuesList() {
  return instance
    .get(`/Journals/GetJournalIssuesList?journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response.data.journalIssues);
    });
}

async function changeIssueStatus(id, statusId) {
  let status = statusId === Status.Active ? Status.Passive : Status.Active;
  const body = {
    id: id,
    status: status,
  };
  return instance.post("Journals/ChangeIssueStatus", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function addOrUpdateIssue(otherParams) {
  const body = {
    journalId: GUID,
    statusId: 1,
    ...otherParams,
  };
  return instance.post("Journals/AddOrUpdateIssue", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function getVolumesList() {
  return instance
    .get("/Journals/GetVolumesList", {
      params: { journalId: GUID },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
}

async function getSections() {
  return instance
    .get(`/Journals/GetJournalSectionsList`, {
      params: { journalId: GUID },
    })
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function addOrUpdateVolume(otherParams) {
  const body = {
    journalId: GUID,
    statusId: 1,
    ...otherParams,
  };
  return instance.post("Journals/AddOrUpdateVolume", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function changeVolumeStatus(id, statusId) {
  let status = statusId === Status.Active ? Status.Passive : Status.Active;
  const body = {
    id: id,
    status: status,
  };
  return instance.post("Journals/ChangeVolumeStatus", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function getVolumeById(id) {
  return instance.get(`/Journals/GetVolumeById?Id=${id}`).then((response) => {
    return Promise.resolve(response.data);
  });
}

async function deleteVolume(volumeId) {
  return instance
    .delete(`/Journals/DeleteVolume?id=${volumeId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function addOrUpdateSection(otherParams) {
  const objArray = otherParams.sectionResearchTopics.map((topic) => {
    return {
      name: topic,
      statusId: 1,
    };
  });

  const body = {
    journalId: GUID,
    ...otherParams,
    sectionResearchTopics: objArray,
  };
  return instance.post("Journals/AddOrUpdateSection", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function getSectionById(id) {
  return instance.get(`/Journals/GetSectionById?Id=${id}`).then((response) => {
    return Promise.resolve(response.data);
  });
}

async function deleteSection(sectionId) {
  return instance
    .delete(`/Journals/DeleteSection?id=${sectionId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function changeSectionStatus(id, statusId) {
  let status = statusId === Status.Active ? Status.Passive : Status.Active;
  const body = {
    id: id,
    status: status,
  };
  return instance
    .post("Journals/ChangeSectionStatus", body)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function getJournal() {
  return instance
    .get(`/Journals/GetJournalById?journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function getJournalTopicsList() {
  return instance
    .get(`/Journals/GetJournalTopicsList?journalId=${GUID}&status=1`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function updateJournal(
  journalName,
  journalAbout,
  journalEditorialOffice,
  journalDescription,
  journalIndexAndArchiving,
  journalAims,
  journalSubjectAreas,
  journalCode,
  journalInformationForAuthors,
  journalInformationForEditors,
  journalInformationForReviewers
) {
  const body = {
    id: GUID,
    name: journalName,
    code: journalCode,
    aims: journalAims,
    about: journalAbout,
    editorialOffice: journalEditorialOffice,
    indexingArchiving: journalIndexAndArchiving,
    subjectAreas: journalSubjectAreas,
    description: journalDescription,
    informationForAuthors: journalInformationForAuthors,
    informationForEditors: journalInformationForEditors,
    informationForReviewers: journalInformationForReviewers,
  };
  return instance.post("/Journals/UpdateJournal", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function addOrUpdateArticleType(otherParams) {
  const body = {
    journalId: GUID,
    statusId: 1,
    ...otherParams,
  };
  return instance
    .post("Journals/AddOrUpdateArticleType", body)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function getArticleTypeList() {
  return instance
    .get(`/Journals/GetArticleTypeList?journalId=${GUID}&status=1`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function deleteArticleType(articleId) {
  return instance
    .delete(`/Journals/DeleteArticleType?id=${articleId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function getArticleTypeById(id) {
  return instance
    .get(`/Journals/GetArticleTypeById?Id=${id}`)
    .then((response) => {
      return Promise.resolve(response.data);
    });
}

async function changeArticleTypeStatus(id, statusId) {
  let status = statusId === Status.Active ? Status.Passive : Status.Active;
  const body = {
    id: id,
    status: status,
  };
  return instance
    .post("Journals/ChangeArticleTypeStatus", body)
    .then((response) => {
      return Promise.resolve(response);
    });
}

export {
  deleteIssue,
  getIssueById,
  getJournalIssuesList,
  changeIssueStatus,
  addOrUpdateIssue,
  getVolumesList,
  getSections,
  addOrUpdateVolume,
  deleteVolume,
  getVolumeById,
  changeVolumeStatus,
  addOrUpdateSection,
  changeSectionStatus,
  getSectionById,
  deleteSection,
  getJournal,
  getJournalTopicsList,
  updateJournal,
  addOrUpdateArticleType,
  getArticleTypeList,
  deleteArticleType,
  getArticleTypeById,
  changeArticleTypeStatus,
};
