import React, { createContext, useEffect, useState } from "react";
import { getAppSettingsByRequestType } from "../endpoints/AppsettingsEndpoints";
import { RequestType } from "../Enums";

const SettingsContext = createContext({
  appIsReady: false,
  setAppIsReady: () => {},
  showEditors: false,
  showEditorsInChief: false,
  showCoEditorsInChief: false,
  showAssociateEditors: false,
  showTechnicalEditors: false,
  showSecretariatList: false,
  showReviewers: false,
  showReviewersBoard: false,
});

function SettingsContextProvider({ children }) {
  const [showEditors, setShowEditors] = useState(false);
  const [showEditorsInChief, setShowEditorsInChief] = useState(false);
  const [showCoEditorsInChief, setShowCoEditorsInChief] = useState(false);
  const [showAssociateEditors, setShowAssociateEditors] = useState(false);
  const [showTechnicalEditors, setShowTechnicalEditors] = useState(false);
  const [showSecretariatList, setShowSecretariatList] = useState(false);
  const [showReviewers, setShowReviewers] = useState(false);
  const [showReviewersBoard, setShowReviewersBoard] = useState(false);

  useEffect(() => {
    getAppSettingsByRequestType(RequestType.Portal).then((res) => {
      setShowEditors(
        res?.appSettings?.find((field) => field.key === "Show Editors")
          ?.isActive
      );
      setShowEditorsInChief(
        res?.appSettings?.find((field) => field.key === "Show Editors-in-Chief")
          ?.isActive
      );
      setShowCoEditorsInChief(
        res?.appSettings?.find(
          (field) => field.key === "Show Co-Editors-in-Chief"
        )?.isActive
      );
      setShowAssociateEditors(
        res?.appSettings?.find(
          (field) => field.key === "Show Associate Editors"
        )?.isActive
      );
      setShowTechnicalEditors(
        res?.appSettings?.find(
          (field) => field.key === "Show Technical Editors"
        )?.isActive
      );
      setShowSecretariatList(
        res?.appSettings?.find((field) => field.key === "Show Secretariat List")
          ?.isActive
      );
      setShowReviewers(
        res?.appSettings?.find((field) => field.key === "Show Reviewers")
          ?.isActive
      );
      setShowReviewersBoard(
        res?.appSettings?.find((field) => field.key === "Show Reviewers Board")
          ?.isActive
      );
    });
  }, []);
  return (
    <SettingsContext.Provider
      value={{
        showEditors,
        showEditorsInChief,
        showCoEditorsInChief,
        showAssociateEditors,
        showTechnicalEditors,
        showSecretariatList,
        showReviewers,
        showReviewersBoard,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}
export { SettingsContext };
export default SettingsContextProvider;
