import React from "react";
import "./Inputs.css";

const CheckBoxes = ({
  label,
  name,
  value,
  id,
  checked,
  onChange,
  className = "",
}) => {
  return (
    <label className={`d-flex align-items-center ${className}`}>
      <input
        className="me-1"
        type="checkbox"
        name={name}
        value={value}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      {label}
    </label>
  );
};

export default CheckBoxes;
