import React, { useState, useEffect } from "react";
import Card from "../components/Card/Card";
import { getCountriesList, deleteCountry } from "../endpoints/CountryEndpoints";
import DeleteModal from "../components/Modals/DeleteModal";
import { errorToast, successToast } from "../HelperFunctions/HelperFunctions";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import EmptyResponse from "../components/EmptyResponse/EmptyResponse";
import Table from "../components/Table/Table";

const CountriesList = () => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCountriesList()
      .then((res) => {
        const copiedArray = res.data.map((obj) => ({
          name: obj.name,
          id: obj.id,
        }));
        setCountries(copiedArray);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleDeleteCountry = (countryId) => {
    deleteCountry(countryId).then((res) => {
      if (res.data.success) {
        successToast(res.data.message);
        const newCountries = countries.filter(
          (obj) => !countryId.includes(obj.id)
        );
        setCountries(newCountries);
        toggleModal();
      } else {
        errorToast(res.data.message);
      }
      toggleModal();
    });
  };

  const toggleModal = (countryId) => {
    setSelectedCountryId(countryId);
    if (showModal) {
      setShowModal(false);
      document.body.style.overflow = "unset";
    } else {
      setShowModal(true);
      document.body.style.overflow = "hidden";
    }
  };

  const tableHeaders = [{ value: "Name" }];

  return (
    <div className="main-content">
      <Card>
        <h3>Countries List</h3>
        <DeleteModal
          show={showModal}
          onCancel={toggleModal}
          onConfirm={() => handleDeleteCountry(selectedCountryId)}
          name={"country"}
        />
        {loading && (
          <LoadingSpinner
            color={"var(--main800)"}
            height={"30px"}
            width={"30px"}
          />
        )}
        {!loading && countries.length > 0 && (
          <Table
            tableHeaders={tableHeaders}
            tableData={countries}
            editNavigateLink={"/addcountry"}
            toggleModal={toggleModal}
          />
        )}
        {!loading && countries.length <= 0 && <EmptyResponse />}
      </Card>
    </div>
  );
};

export default CountriesList;
