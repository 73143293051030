import React from "react";

const LanguageSetting = ({
  journalLangages,
  setJournalLanguages,
  dispatchJournalDataMenu2,
}) => {
  const handleUncheckLanguage = (value) => {
    setJournalLanguages((prevLanguages) => {
      const languagesArr = JSON.parse(prevLanguages.value);
      languagesArr.push(value);
      dispatchJournalDataMenu2({
        type: "CHANGE_LANGUAGE",
        id: journalLangages.id,
        value: JSON.stringify(languagesArr),
      });
      return {
        ...prevLanguages,
        value: JSON.stringify(languagesArr),
      };
    });
  };

  const handleCheckLanguage = (value) => {
    setJournalLanguages((prevLanguages) => {
      const languagesArr = JSON.parse(prevLanguages.value);
      const indexOfLang = languagesArr.indexOf(value);
      languagesArr.splice(indexOfLang, 1);
      dispatchJournalDataMenu2({
        type: "CHANGE_LANGUAGE",
        id: journalLangages.id,
        value: JSON.stringify(languagesArr),
      });
      return {
        ...prevLanguages,
        value: JSON.stringify(languagesArr),
      };
    });
  };

  const handleChangeLanguage = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      handleUncheckLanguage(value);
    } else {
      handleCheckLanguage(value);
    }
  };
  return (
    <div className="d-flex flex-wrap">
      <label className="mb-1 col-6" style={{ cursor: "unset" }}>
        Languages:
      </label>
      <div className="col-6 d-flex flex-column" style={{ gap: "5px" }}>
        <label className="d-flex align-items-center">
          <input
            className="me-1"
            type="checkbox"
            value={"en-us"}
            id={"en-us"}
            checked={JSON.parse(journalLangages?.value).includes("en-us")}
            onChange={(event) => {
              handleChangeLanguage(event);
            }}
          />
          English
        </label>
        <label className="d-flex align-items-center">
          <input
            className="me-1"
            type="checkbox"
            value={"sq"}
            id={"sq"}
            checked={JSON.parse(journalLangages?.value).includes("sq")}
            onChange={(event) => {
              handleChangeLanguage(event);
            }}
          />
          Albanian
        </label>
      </div>
    </div>
  );
};

export default LanguageSetting;
