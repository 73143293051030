import React from "react";
import "./ButtonWithLoader.css";

function ButtonWithLoader({
  isLoading,
  onClick = () => {},
  buttonProps = {},
  classNames = ["mainBtn"],
  extraClasses = [],
  width = "23px",
  height = "23px",
  children,
}) {
  return (
    <button
      className={`btn ${classNames.join(" ")} ${extraClasses.join(" ")}`}
      {...buttonProps}
      disabled={isLoading || buttonProps.disabled}
      onClick={onClick}
    >
      {!isLoading && children}
      {isLoading && (
        <div className="d-flex align-items-center justify-content-center">
          <svg
            className="ring"
            viewBox="25 25 50 50"
            strokeWidth="5"
            color="white"
            style={{ width: width, height: height }}
          >
            <circle cx="50" cy="50" r="20" />
          </svg>
        </div>
      )}
    </button>
  );
}

export default ButtonWithLoader;
