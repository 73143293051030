import axios from "axios";
import { baseURL } from "../JournalSettings";

const instance = axios.create({
  baseURL: baseURL,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const Endpoints = ({setShowSessionExpiredModal})=> {
  async function refreshAccessToken() {
    try {
      const response = await axios.post(`${baseURL}/Login/Refresh`, {
        refreshToken: localStorage.getItem("refreshToken"),
        token: localStorage.getItem("accessToken"),
      });
      localStorage.setItem("accessToken", response.data.token);
      localStorage.setItem("refreshToken", response.data.refreshToken);
    } catch (err) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      setShowSessionExpiredModal(true)
      throw err;
    }
  }
  
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          await refreshAccessToken();
          originalRequest.headers.Authorization = `Bearer ${localStorage.getItem(
            "accessToken"
          )}`;
          return instance(originalRequest);
        } catch (err) {
          console.error(err);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          throw err;
        }
      }
      return Promise.reject(error);
    }
  );
  return <></>
}
export{Endpoints}

export default instance;
