import React, { useEffect, useReducer, useState } from "react";
import Card from "../../components/Card/Card";
import "react-quill/dist/quill.snow.css";
import {
  errorToast,
  successToast,
} from "../../HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../../components/ButtonWithLoader/ButtonWithLoader";
import {
  getAppSettingDetails,
  updateAppSettingValues,
} from "../../endpoints/AppsettingsEndpoints";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { AppSettingType, MenuType } from "../../Enums";
import LanguageSetting from "./LanguageSetting";
import CorrespondingAuthorSetting from "./CorrespondingAuthorSetting";
import { BsUpload } from "react-icons/bs";
import ImagesDisplay from "./ImagesDisplay";
import "./ManageAppSettings.css";

function journalDataDispatcher(state, action) {
  if (action.type === "INITIAL_STATE") {
    return action.data;
  }
  if (action.type === "EDIT_FIELD") {
    const findEditedField = state.find((el) => el.id === action.id);
    if (findEditedField) {
      const indexOfEditedField = state.indexOf(findEditedField);
      const editedObj = state[indexOfEditedField];
      editedObj.value = action.value;
      return [...state];
    }
  }
  if (action.type === "TOGGLE_ACTIVE_FIELD") {
    const findEditedField = state.find((el) => el.id === action.id);
    if (findEditedField) {
      const indexOfEditedField = state.indexOf(findEditedField);
      const editedObj = state[indexOfEditedField];
      editedObj.isActive = !editedObj.isActive;
      return [...state];
    }
  }

  if (action.type === "CHANGE_LANGUAGE") {
    const findEditedField = state.find((el) => el.id === action.id);
    if (findEditedField) {
      const indexOfEditedField = state.indexOf(findEditedField);
      const editedObj = state[indexOfEditedField];
      editedObj.value = action.value;
      return [...state];
    }
  }
  return state;
}

const ManageSettings = () => {
  const [journalDataMenu2, dispatchJournalDataMenu2] = useReducer(
    journalDataDispatcher,
    []
  );
  const [bannerImages, setBannerImages] = useState([]);
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);
  const [journalCode, setJournalCode] = useState("");
  const [journalName, setJournalName] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [journalLangages, setJournalLanguages] = useState({});
  const [correspondingAuthor, setCorrespondingAuthor] = useState();
  const [bannerTitle, setBannerTitle] = useState();

  useEffect(() => {
    getAppSettingDetails(MenuType.SettingsData)
      .then((res) => {
        dispatchJournalDataMenu2({
          type: "INITIAL_STATE",
          data: res.data.appSettings,
        });
        setJournalCode(res.data.code);
        setJournalName(res.data.name);
        setJournalLanguages(
          res.data.appSettings.find((el) => el.key == "Languages" && el.value)
        );
        setBannerImages(res.data.bannerImages);

        setBannerTitle(
          res.data.appSettings.find((item) => item.key === "Show Banner Title")
        );
        setCorrespondingAuthor(
          res.data.appSettings.find(
            (el) => el.key == "Allow Multi Corresponding Author" && el.value
          )
        );
      })
      .finally(() => setIsReady(true))
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    updateAppSettingValues(
      journalDataMenu2,
      journalCode,
      journalName,
      bannerImages
    )
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  function handleToggle(value, id) {
    dispatchJournalDataMenu2({ type: "TOGGLE_ACTIVE_FIELD", value, id });
  }

  function handleChange(editedValue, id) {
    let value;
    if (editedValue > 100000) {
      value = 100000;
      errorToast("Max value of this field is 100000");
    } else {
      value = editedValue;
    }
    dispatchJournalDataMenu2({ type: "EDIT_FIELD", value, id });
  }

  function handleChangeTitle(editedValue, id) {
    dispatchJournalDataMenu2({ type: "EDIT_FIELD", value: editedValue, id });
  }

  function handleUploadImages(e) {
    const files = e.target.files;
    const updatedImages = [...bannerImages];
    for (const file of files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const imageData = reader.result?.split(",")[1];
        const uploadedImg = {
          image: imageData,
          imageName: file.name,
          imageContentType: file.type,
        };
        updatedImages.push(uploadedImg);
        setBannerImages(updatedImages);
        e.target.value = null;
      };
    }
  }

  const handleDeleteImage = (index) => {
    const updatedImages = bannerImages.filter((_, i) => i !== index);
    setBannerImages(updatedImages);
  };

  return (
    <div className="main-content">
      {!isReady && (
        <LoadingSpinner
          color={"var(--main800)"}
          height={"30px"}
          width={"30px"}
        />
      )}
      {isReady && (
        <>
          <Card>
            <h3>Edit Settings</h3>
            <form
              onSubmit={handleSave}
              className="manageSettingsForm mt-2 col-xxl-6 col-md-10 col-12"
            >
              <LanguageSetting
                journalLangages={journalLangages}
                setJournalLanguages={setJournalLanguages}
                dispatchJournalDataMenu2={dispatchJournalDataMenu2}
              />
              <CorrespondingAuthorSetting
                journalDataMenu2={journalDataMenu2}
                correspondingAuthor={correspondingAuthor}
                handleChange={handleChange}
              />
              {journalDataMenu2.map((journalField) => {
                if (
                  journalField.menuId != MenuType.SettingsData ||
                  journalField.key == "Languages" ||
                  journalField.key == "Allow Multi Corresponding Author" ||
                  journalField.key == "Show Banner Title"
                )
                  return;

                return (
                  <div
                    className="d-flex align-items-center flex-wrap"
                    key={journalField.id}
                  >
                    <label className="mb-1 col-6" style={{ cursor: "unset" }}>
                      {journalField.key}:
                    </label>

                    <div className="col-6">
                      {journalField.type === AppSettingType.Checkbox && (
                        <>
                          <input
                            type="checkbox"
                            value={journalField.key}
                            checked={journalField?.isActive}
                            onChange={() => {
                              handleToggle(
                                journalField.isActive,
                                journalField.id
                              );
                            }}
                          />
                          {journalField.key === "Show Banner" &&
                            journalField.isActive && (
                              <>
                                <label
                                  htmlFor="image-upload"
                                  className="upload-icon"
                                >
                                  <BsUpload />
                                  <p>Choose images to upload</p>
                                </label>
                                <input
                                  id="image-upload"
                                  multiple
                                  className="d-none"
                                  accept="image/*"
                                  type="file"
                                  onChange={(e) => {
                                    handleUploadImages(e);
                                  }}
                                />
                                <div
                                  className="d-flex flex-wrap mt-3"
                                  style={{ gap: "10px" }}
                                >
                                  <ImagesDisplay
                                    imageList={bannerImages}
                                    handleDeleteImage={handleDeleteImage}
                                  />
                                </div>
                              </>
                            )}
                        </>
                      )}
                      {journalField.type === AppSettingType.TextboxNumber && (
                        <input
                          className="form-control"
                          style={{ width: "fit-content" }}
                          type="number"
                          value={journalField.value}
                          onChange={(e) => {
                            handleChange(e.target.value, journalField.id);
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              })}

              {(() => {
                const showbanner = journalDataMenu2.find(
                  (item) => item.key === "Show Banner"
                )?.isActive;

                if (showbanner) {
                  return (
                    <div className="d-flex flex-wrap">
                      <label className="mb-1 col-6" style={{ cursor: "unset" }}>
                        Show Banner Title:
                      </label>
                      <div
                        className="col-6 d-flex flex-column"
                        style={{ gap: "5px" }}
                      >
                        <input
                          type="checkbox"
                          value={bannerTitle?.isActive}
                          checked={bannerTitle?.isActive}
                          onChange={() => {
                            handleToggle(
                              bannerTitle?.isActive,
                              bannerTitle?.id
                            );
                          }}
                        />
                        {bannerTitle?.isActive && (
                          <input
                            className="form-control w-100"
                            type="text"
                            value={bannerTitle?.value}
                            onChange={(e) => {
                              handleChangeTitle(
                                e.target.value,
                                bannerTitle?.id
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                }
                return null;
              })()}

              <div className="d-flex justify-content-end mt-3 ">
                <ButtonWithLoader isLoading={isRequestBeingMade}>
                  Save Settings
                </ButtonWithLoader>
              </div>
            </form>
          </Card>
        </>
      )}
    </div>
  );
};

export default ManageSettings;
