import React, { useState, useEffect } from "react";
import Input from "../components/Inputs/Input";
import Card from "../components/Card/Card";
import {
  addCountry,
  getCountryById,
  updateCountry,
} from "../endpoints/CountryEndpoints";
import { useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../components/ButtonWithLoader/ButtonWithLoader";

const AddCountry = () => {
  const [countryName, setCountryName] = useState("");
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  const { countryId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (countryId) {
      getCountryById(countryId)
        .then((country) => {
          setCountryName(country.name || "");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setCountryName("");
    }
  }, [countryId]);

  const handleSave = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    addCountry(countryName)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          navigate("/countrieslist");
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    updateCountry(countryId, countryName)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          navigate("/countrieslist");
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  return (
    <div className="main-content">
      <Card>
        <h3>{countryId ? "Edit" : "Add"} Country</h3>
        <div className="row mt-2">
          <form onSubmit={countryId ? handleEdit : handleSave}>
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "countryName",
                  id: "countryName",
                  required: true,
                  maxLength: "255",
                }}
                value={countryName}
                onChange={(e) => setCountryName(e.target.value)}
                label={"Name"}
                errorMessage={"Field Cannot be Empty"}
              />

              <div className="d-flex justify-content-end mt-2">
                <div
                  className="btn me-2 d-flex align-items-center"
                  onClick={() => navigate("/countrieslist")}
                >
                  Cancel
                </div>
                <ButtonWithLoader isLoading={isRequestBeingMade}>
                  {" "}
                  {countryId ? "Edit" : "Add"} Country
                </ButtonWithLoader>
              </div>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default AddCountry;
