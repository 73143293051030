import React, { useEffect, useState } from "react";
import Card from "../components/Card/Card";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { getAdminDashboard } from "../endpoints/DashboardEndpoints";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";

const Dashboard = () => {
  const [articlesByMonth, setArticlesByMonth] = useState(null);
  const [articlesByType, setArticlesByType] = useState(null);
  const [articlesBySection, setArticlesBySection] = useState(null);
  const [numberOfAuthors, setNumberOfAuthors] = useState(null);
  const [numberOfAdmins, setNumberOfAdmins] = useState(null);
  const [numberOfEditors, setNumberOfEditors] = useState(null);
  const [numberOfChiefEditors, setNumberOfChiefEditors] = useState(null);
  const [activeIssues, setActiveIssues] = useState(null);
  const [articesInReview, setArticesInReview] = useState(null);
  const [publishedArticles, setPublishedArticles] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAdminDashboard()
      .then((res) => {
        setArticlesByMonth(res.data.articlesByMonth);
        setArticlesByType(res.data.articlesByType);
        setArticlesBySection(res.data.articlesBySection);
        setNumberOfAuthors(res.data.numberOfAuthors);
        setNumberOfAdmins(res.data.numberOfAdmins);
        setNumberOfEditors(res.data.numberOfEditors);
        setNumberOfChiefEditors(res.data.numberOfChiefEditors);
        setActiveIssues(res.data.activeIssues);
        setArticesInReview(res.data.articesInReview);
        setPublishedArticles(res.data.publishedArticles);
      })
      .then(() => setLoading(false))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const CustomTickFormatter = (name) => {
    const maxLength = 10;
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + "...";
    }
    return name;
  };

  return (
    <div className="main-content">
      {loading && (
        <LoadingSpinner
          color={"var(--main800)"}
          height={"30px"}
          width={"30px"}
        />
      )}
      {!loading && (
        <div>
          <div className="row">
            <div className="col-md-6 col-xl-3 mb-1">
              <Card>
                <div className="text-center">
                  <p className="text-uppercase fst-normal">Total Authors</p>
                  <h4 className="mb-1 mt-1">
                    <span className="counter-value">{numberOfAuthors}</span>
                  </h4>
                </div>
              </Card>
            </div>
            <div className="col-md-6 col-xl-3 mb-1">
              <Card>
                <div className="text-center">
                  <p className="text-uppercase fst-normal">Total Admin Users</p>
                  <h4 className="mb-1 mt-1">
                    <span className="counter-value">{numberOfAdmins}</span>
                  </h4>
                </div>
              </Card>
            </div>
            <div className="col-md-6 col-xl-3 mb-1">
              <Card>
                <div className="text-center">
                  <p className="text-uppercase fst-normal">Total Editors</p>
                  <h4 className="mb-1 mt-1">
                    <span className="counter-value">{numberOfEditors}</span>
                  </h4>
                </div>
              </Card>
            </div>
            <div className="col-md-6 col-xl-3 mb-1">
              <Card>
                <div className="text-center">
                  <p className="text-uppercase fst-normal">
                    Total Editors-in-Chief
                  </p>
                  <h4 className="mb-1 mt-1">
                    <span className="counter-value">
                      {numberOfChiefEditors}
                    </span>
                  </h4>
                </div>
              </Card>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6 col-xl-4 mb-1">
              <Card>
                <div className="text-center">
                  <p className="text-uppercase fst-normal">
                    Total Publications
                  </p>
                  <h4 className="mb-1 mt-1">
                    <span className="counter-value">{publishedArticles}</span>
                  </h4>
                </div>
              </Card>
            </div>
            <div className="col-md-6 col-xl-4 mb-1">
              <Card>
                <div className="text-center">
                  <p className="text-uppercase fst-normal">
                    Total Publications Under Review
                  </p>
                  <h4 className="mb-1 mt-1">
                    <span className="counter-value">{articesInReview}</span>
                  </h4>
                </div>
              </Card>
            </div>
            <div className="col-md-12 col-xl-4 mb-1">
              <Card>
                <div className="text-center">
                  <p className="text-uppercase fst-normal">
                    Total Active Issues
                  </p>
                  <h4 className="mb-1 mt-1">
                    <span className="counter-value">{activeIssues}</span>
                  </h4>
                </div>
              </Card>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12 col-xl-12 mb-1">
              <Card>
                <p className="text-muted text-uppercase fw-semibold">
                  Monthly Publications
                </p>
                <div style={{ overflow: "auto" }}>
                  <ResponsiveContainer minWidth={700} minHeight={240}>
                    <BarChart
                      style={{ margin: "0 auto" }}
                      width={700}
                      height={300}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      data={articlesByMonth}
                    >
                      <XAxis
                        dataKey="name"
                        stroke="var(--main05)"
                        tickFormatter={(name) => name.slice(0, 3)}
                      />
                      <YAxis />
                      <Tooltip
                        wrapperStyle={{
                          width: "fit-content",
                          backgroundColor: "#ccc",
                          textTransform: "capitalize",
                        }}
                      />
                      <CartesianGrid
                        stroke="var(--main05)"
                        strokeDasharray="5 5"
                      />
                      <Bar
                        dataKey="amount"
                        fill="rgba(140, 127, 182, 0.8)"
                        barSize={35}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </Card>
            </div>
            <div className="col-md-12 col-xl-6 mb-1 mt-3">
              <Card>
                <p className="text-muted text-uppercase fw-semibold">
                  Publications by types
                </p>
                <div style={{ overflow: "auto" }}>
                  <ResponsiveContainer minWidth={700} minHeight={240}>
                    <BarChart
                      style={{ margin: "0 auto" }}
                      width={700}
                      height={300}
                      data={articlesByType}
                    >
                      <XAxis
                        dataKey="name"
                        stroke="var(--main05)"
                        tickFormatter={CustomTickFormatter}
                      />
                      <YAxis />
                      <Tooltip
                        wrapperStyle={{
                          width: "fit-content",
                          backgroundColor: "#ccc",
                          textTransform: "capitalize",
                        }}
                      />
                      <CartesianGrid
                        stroke="var(--main05)"
                        strokeDasharray="5 5"
                      />
                      <Bar
                        dataKey="published"
                        stackId="a"
                        fill="rgba(95, 167, 182, 0.8)"
                        barSize={35}
                      />
                      <Bar
                        dataKey="underReview"
                        stackId="a"
                        fill="rgba(189, 214, 189, 0.8)"
                        barSize={35}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </Card>
            </div>

            <div className="col-md-12 col-xl-6 mb-1 mt-3">
              <Card>
                <p className="text-muted text-uppercase fw-semibold">
                  Publications by sections
                </p>
                <div style={{ overflow: "auto" }}>
                  <ResponsiveContainer minWidth={700} minHeight={240}>
                    <BarChart
                      style={{ margin: "0 auto" }}
                      width={700}
                      height={300}
                      data={articlesBySection}
                    >
                      <XAxis
                        dataKey="name"
                        stroke="var(--main05)"
                        tickFormatter={CustomTickFormatter}
                      />
                      <Tooltip labelFormatter={(name) => name} />
                      <YAxis />
                      <Tooltip
                        wrapperStyle={{
                          width: "fit-content",
                          backgroundColor: "#ccc",
                          textTransform: "capitalize",
                        }}
                      />
                      <CartesianGrid
                        stroke="var(--main05)"
                        strokeDasharray="5 5"
                      />
                      <Bar
                        dataKey="published"
                        stackId="a"
                        fill="rgba(202, 111, 107, 0.8)"
                        barSize={35}
                      />
                      <Bar
                        dataKey="underReview"
                        stackId="a"
                        fill="rgba(255,218,185, 0.8)"
                        barSize={35}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
