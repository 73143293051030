import React, { useEffect, useState } from "react";
import Card from "../components/Card/Card";
import Table from "../components/Table/Table";
import { GetNews, deleteNews, getNews } from "../endpoints/News";
import { NewsStatus } from "../Enums";
import DeleteModal from "../components/Modals/DeleteModal";
import { useNavigate } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  errorToast,
  getNewsStatusName,
  successToast,
} from "../HelperFunctions/HelperFunctions";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import EmptyResponse from "../components/EmptyResponse/EmptyResponse";

const NewsList = () => {
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState();

  const tableHeaders = [
    { value: "Title" },
    { value: "Created By" },
    { value: "Created On" },
    { value: "Status" },
  ];

  useEffect(() => {
    setLoading(true);
    getNews()
      .then((res) => {
        const copiedArray = res.data.news.map((obj) => ({
          title: obj.title,
          createdBy: obj.createdBy,
          createdOn: obj.createdOn.substring(0, 10),
          statusId: obj.statusId,
          id: obj.id,
        }));
        setTableData(copiedArray);
      })
      .finally(() => setLoading(false));
  }, []);

  const toggleModal = (newsId) => {
    setSelectedNews(newsId);
    if (showModal) {
      setShowModal(false);
      document.body.style.overflow = "unset";
    } else {
      setShowModal(true);
      document.body.style.overflow = "hidden";
    }
  };

  const handleDeleteNews = (newsId) => {
    deleteNews(newsId).then((res) => {
      if (res.data.success) {
        successToast(res.data.message);
        const newList = tableData.filter((obj) => !newsId.includes(obj.id));
        setTableData(newList);
        toggleModal();
      } else {
        errorToast(res.data.message);
      }
      toggleModal();
    });
  };

  return (
    <div className="main-content">
      <DeleteModal
        show={showModal}
        onCancel={toggleModal}
        onConfirm={() => handleDeleteNews(selectedNews)}
        name={"news"}
      />
      <Card>
        <h3>News List</h3>
        <div className="table-container">
          {loading && (
            <LoadingSpinner
              color={"var(--main800)"}
              height={"30px"}
              width={"30px"}
            />
          )}
          {!loading && tableData?.length > 0 && (
            <table className="table table-bordered styled-table mt-2">
              <thead>
                <tr>
                  {tableHeaders?.map((tableHeader, index) => {
                    {
                      return <th key={index}>{tableHeader.value}</th>;
                    }
                  })}
                  <th></th>
                </tr>
              </thead>
              {tableData && (
                <tbody>
                  {Object.keys(tableData).map((i) => {
                    const data = tableData[i];
                    return (
                      <tr key={i}>
                        {Object.values(data).map((j, index) => {
                          const id = Object.values(data).pop();
                          const currentStatus = data.statusId;
                          if (j === id) {
                            return (
                              <td
                                key={index}
                                data-cell={Object.keys(data)[index]}
                              >
                                <div className="d-flex justify-content-evenly align-items-center">
                                  <BiEditAlt
                                    className="edit-icon"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => navigate(`/addnews/${id}`)}
                                  />
                                  <RiDeleteBinLine
                                    className="delete--icon"
                                    color="red"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => toggleModal(id)}
                                  />
                                </div>
                              </td>
                            );
                          } else if (j === currentStatus) {
                            return (
                              <td
                                key={index}
                                data-cell={Object.keys(data)[index]}
                              >
                                {currentStatus == -1 && (
                                  <span>
                                    {getNewsStatusName(NewsStatus.Passive)}
                                  </span>
                                )}
                                {currentStatus == 1 && (
                                  <span>
                                    {getNewsStatusName(NewsStatus.SaveDraft)}
                                  </span>
                                )}
                                {currentStatus == 2 && (
                                  <span>
                                    {getNewsStatusName(NewsStatus.Published)}
                                  </span>
                                )}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={index}
                                data-cell={Object.keys(data)[index]}
                              >
                                <span> {j}</span>
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          )}
          {!loading && tableData?.length <= 0 && <EmptyResponse />}
        </div>
      </Card>
    </div>
  );
};

export default NewsList;
