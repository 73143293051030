import { GUID } from "../JournalSettings";
import "react-toastify/dist/ReactToastify.css";
import instance from "./Instance";

async function addOrUpdateNews(otherParams) {
  const body = {
    journalId: GUID,
    ...otherParams,
  };
  return instance.post("News/AddOrUpdateNews", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function getNewsById(id) {
  return instance
    .get(`/News/GetNewsById?Id=${id}&journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function getNews() {
  return instance.get(`/News/GetNews?journalId=${GUID}`).then((response) => {
    return Promise.resolve(response);
  });
}

async function deleteNews(newsId) {
  return instance.delete(`/News/DeleteNews?id=${newsId}`).then((response) => {
    return Promise.resolve(response);
  });
}

async function changeStatus(newsId, status) {
  const body = {
    journalId: GUID,
    statusId: status,
    id: newsId,
  };
  return instance.post("News/ChangeStatus", body).then((response) => {
    return Promise.resolve(response);
  });
}

export { addOrUpdateNews, getNewsById, getNews, deleteNews, changeStatus };
