import React, { useState, useEffect } from "react";
import Card from "../components/Card/Card";
import {
  getJournalUsers,
  deleteUser,
  changeUserStatus,
} from "../endpoints/UserEndpoints";
import DeleteModal from "../components/Modals/DeleteModal";
import {
  errorToast,
  statusName,
  successToast,
} from "../HelperFunctions/HelperFunctions";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import EmptyResponse from "../components/EmptyResponse/EmptyResponse";
import Table from "../components/Table/Table";

const EditorsList = ({ userRole, userRoleType }) => {
  const [editors, setEditors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEditorId, setSelectedEditorId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    getJournalUsers(userRole, userRoleType)
      .then((res) => {
        const copiedArray = res.data.users.map((obj) => ({
          profilePhoto: obj.profilePhoto || "",
          name: `${obj.firstName} ${obj.lastName}`,
          title: obj.title,
          email: obj.email,
          universityOrOrganization: obj.universityOrOrganization,
          country: obj.country.name,
          statusId: obj.statusId,
          id: obj.id,
        }));
        setEditors(copiedArray);
      })
      .finally(() => setLoading(false));
  }, [userRole]);

  const handleDeleteEditor = (editorId) => {
    deleteUser(editorId).then((res) => {
      if (res.data.success) {
        successToast(res.data.message);
        const newEditors = editors.filter((obj) => !editorId.includes(obj.id));
        setEditors(newEditors);
        toggleModal();
      } else {
        errorToast(res.data.message);
      }
      toggleModal();
    });
  };

  const toggleModal = (editorId) => {
    setSelectedEditorId(editorId);
    if (showModal) {
      setShowModal(false);
      document.body.style.overflow = "unset";
    } else {
      setShowModal(true);
      document.body.style.overflow = "hidden";
    }
  };

  const tableHeaders = [
    { value: "" },
    { value: "Name" },
    { value: "Title" },
    { value: "Email" },
    { value: "University or Organization" },
    { value: "Country" },
    { value: "Status" },
  ];

  return (
    <div className="main-content">
      <Card>
        <DeleteModal
          show={showModal}
          onCancel={toggleModal}
          onConfirm={() => handleDeleteEditor(selectedEditorId)}
          name={"editor"}
        />
        <h3>Editors List</h3>
        {loading && (
          <LoadingSpinner
            color={"var(--main800)"}
            height={"30px"}
            width={"30px"}
          />
        )}
        {!loading && editors.length > 0 && (
          <Table
            tableHeaders={tableHeaders}
            tableData={editors}
            editNavigateLink={"/addeditor"}
            toggleModal={toggleModal}
            changeStatus={changeUserStatus}
            setValues={setEditors}
          />
        )}
        {!loading && editors.length <= 0 && <EmptyResponse />}
      </Card>
    </div>
  );
};

export default EditorsList;
