import React from "react";
import "./Table.css";
import { useNavigate } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  errorToast,
  handleChangeStatus,
  statusName,
  successToast,
} from "../../HelperFunctions/HelperFunctions";
import { Status } from "../../Enums";
import DefaultImage from "../../images/default-image.jpg";

function Table({
  tableHeaders,
  tableData,
  editNavigateLink,
  toggleModal,
  changeStatus,
  setValues,
}) {
  const navigate = useNavigate();

  return (
    <>
      <div className="table-container">
        <table className="table table-bordered styled-table mt-2">
          <thead>
            <tr>
              {tableHeaders?.map((tableHeader, index) => {
                {
                  return <th key={index}>{tableHeader.value}</th>;
                }
              })}
              <th></th>
            </tr>
          </thead>
          {tableData && (
            <tbody>
              {Object.keys(tableData).map((i) => {
                const data = tableData[i];
                return (
                  <tr key={i}>
                    {Object.values(data).map((j, index) => {
                      const id = Object.values(data).pop();
                      const currentStatus = data.statusId;
                      const photo = data.profilePhoto;
                      if (j === id) {
                        return (
                          <td key={index} data-cell={Object.keys(data)[index]}>
                            <div className="d-flex justify-content-evenly align-items-center">
                              {(() => {
                                if (changeStatus) {
                                  return (
                                    <button
                                      className="btn btn-outline-secondary"
                                      style={{
                                        width: "115px",
                                        padding: "5px 0px",
                                      }}
                                      onClick={() => {
                                        changeStatus(id, data.statusId).then(
                                          (res) => {
                                            if (res.data.success) {
                                              successToast(res.data.message);
                                            } else {
                                              errorToast(res.data.message);
                                            }
                                            handleChangeStatus(
                                              id,
                                              data.statusId,
                                              tableData,
                                              setValues
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      {data.statusId === Status.Active
                                        ? "Make Passive"
                                        : "Make Active"}
                                    </button>
                                  );
                                }
                              })()}
                              <BiEditAlt
                                className="edit-icon"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`${editNavigateLink}/${id}`)
                                }
                              />
                              <RiDeleteBinLine
                                className="delete--icon"
                                color="red"
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleModal(id)}
                              />
                            </div>
                          </td>
                        );
                      } else if (j === currentStatus) {
                        return (
                          <td key={index} data-cell={Object.keys(data)[index]}>
                            {currentStatus === 1
                              ? statusName(1)
                              : statusName(0)}
                          </td>
                        );
                      } else if (j === photo) {
                        if (data.profilePhoto !== "") {
                          return (
                            <td
                              key={index}
                              data-cell={Object.keys(data)[index]}
                            >
                              <span>
                                <img
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                  src={data.profilePhoto}
                                  alt="users pic"
                                />
                              </span>
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={index}
                              data-cell={Object.keys(data)[index]}
                            >
                              <span>
                                <img
                                  src={DefaultImage}
                                  alt="default pic"
                                  style={{ width: "30px" }}
                                />
                              </span>
                            </td>
                          );
                        }
                      } else {
                        return (
                          <td key={index} data-cell={Object.keys(data)[index]}>
                            <span> {j}</span>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
}

export default Table;
