import React, { useState, useEffect, useContext } from "react";
import Card from "../components/Card/Card";
import Input from "../components/Inputs/Input";
import "react-toastify/dist/ReactToastify.css";
import RadioButtons from "../components/Inputs/RadioButtons";
import { TiDeleteOutline } from "react-icons/ti";
import { getUserById, updateUser } from "../endpoints/UserEndpoints";
import { addUser } from "../endpoints/LoginEndpoints";
import { UserRole, UserRoleType } from "../Enums";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import {
  errorToast,
  getUserTypeName,
  successToast,
} from "../HelperFunctions/HelperFunctions";
import Textarea from "../components/Inputs/Textarea";
import ButtonWithLoader from "../components/ButtonWithLoader/ButtonWithLoader";
import { SettingsContext } from "../store/SettingsContext";
import CheckBoxes from "../components/Inputs/Checkboxes";

function initEditorObject(user = null) {
  const userObject = {
    email: user?.email || "",
    firstName: user?.firstName || "",
    middleName: user?.middleName || "",
    lastName: user?.lastName || "",
    title: user?.title || "",
    universityOrOrganization: user?.universityOrOrganization || "",
    department: user?.department || "",
    addressLine1: user?.addressLine1 || "",
    addressLine2: user?.addressLine2 || "",
    zipCode: user?.zipCode || "",
    city: user?.city || "",
    biography: user?.biography || "",
    countryId: user?.countryId || "",
    profilePhoto: user?.profilePhoto?.split(",")[1] || "",
    website: user?.website || "",
    interests: user?.interests || "",
    phoneNo: user?.phoneNo || "",
    // userRoleType: user?.userRoleType || null,
    // role: user?.role || null,
    roles: user?.roles || [],
  };
  return userObject;
}

const AddEditor = () => {
  const [countries, setCountries] = useState([]);
  const { userId } = useParams();
  const navigate = useNavigate();
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  const [values, setValues] = useState(initEditorObject());

  const {
    showEditors,
    showEditorsInChief,
    showCoEditorsInChief,
    showAssociateEditors,
    showTechnicalEditors,
    showSecretariatList,
    showReviewers,
    showReviewersBoard,
  } = useContext(SettingsContext);

  useEffect(() => {
    if (userId) {
      getUserById(userId)
        .then((user) => {
          setValues(initEditorObject(user));
          setCountries(user.countries);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      getUserById().then((user) => {
        setCountries(user.countries);
      });
      setValues(initEditorObject());
    }
  }, [userId]);

  const countriesoptions = countries.map((country) => ({
    value: country.id,
    label: country.name,
  }));

  function onChange(e) {
    const name = e.target.name || e.target.title;
    let value = e.target.value;
    if (name === "userRoleType") {
      const isChecked = e.target.checked;
      if (
        value == UserRoleType.EditorInChief ||
        value == UserRoleType.CoEditorInChief
      ) {
        // setValues((prev) => ({
        //   ...prev,
        //   roles: [
        //     ...prev.roles,
        //     { role: UserRole.ChiefEditor, userRoleType: +value },
        //   ],
        // }));

        setValues((prev) => {
          const index = prev.roles.findIndex(
            (role) => role.userRoleType === +value
          );

          let updatedRoles = [...prev.roles];
          if (isChecked) {
            if (index === -1) {
              updatedRoles.push({
                role: UserRole.ChiefEditor,
                userRoleType: +value,
              });
            }
          } else {
            if (index !== -1) {
              updatedRoles.splice(index, 1);
            }
          }

          return {
            ...prev,
            roles: updatedRoles,
          };
        });
      } else if (
        value == UserRoleType.Editor ||
        value == UserRoleType.AssociateEditor ||
        value == UserRoleType.TechnicalEditor ||
        value == UserRoleType.Secretariat
      ) {
        setValues((prev) => {
          const index = prev.roles.findIndex(
            (role) => role.userRoleType === +value
          );

          let updatedRoles = [...prev.roles];
          if (isChecked) {
            if (index === -1) {
              updatedRoles.push({
                role: UserRole.Editor,
                userRoleType: +value,
              });
            }
          } else {
            if (index !== -1) {
              updatedRoles.splice(index, 1);
            }
          }

          return {
            ...prev,
            roles: updatedRoles,
          };
        });
        // role = UserRole.Editor;
      } else if (value == UserRoleType.Reviewer) {
        // role = UserRole.Reviewer;
        setValues((prev) => {
          const index = prev.roles.findIndex(
            (role) => role.userRoleType === +value
          );

          let updatedRoles = [...prev.roles];
          if (isChecked) {
            if (index === -1) {
              updatedRoles.push({
                role: UserRole.Reviewer,
                userRoleType: +value,
              });
            }
          } else {
            if (index !== -1) {
              updatedRoles.splice(index, 1);
            }
          }

          return {
            ...prev,
            roles: updatedRoles,
          };
        });
      } else if (value == UserRoleType.ReviewersBoard) {
        setValues((prev) => {
          const index = prev.roles.findIndex(
            (role) => role.userRoleType === +value
          );

          let updatedRoles = [...prev.roles];
          if (isChecked) {
            if (index === -1) {
              updatedRoles.push({
                role: UserRole.ReviewerBoard,
                userRoleType: +value,
              });
            }
          } else {
            if (index !== -1) {
              updatedRoles.splice(index, 1);
            }
          }

          return {
            ...prev,
            roles: updatedRoles,
          };
        });
      } else if (value == UserRoleType.Admin) {
        setValues((prev) => {
          const index = prev.roles.findIndex(
            (role) => role.userRoleType === +value
          );

          let updatedRoles = [...prev.roles];
          if (isChecked) {
            if (index === -1) {
              updatedRoles.push({
                role: UserRole.Admin,
                userRoleType: +value,
              });
            }
          } else {
            if (index !== -1) {
              updatedRoles.splice(index, 1);
            }
          }

          return {
            ...prev,
            roles: updatedRoles,
          };
        });
      }
      // setValues((prev) => ({ ...prev, role: role }));
      // value = +value;
    }
    setValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  const handleEdit = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    updateUser(userId, values)
      .then((res) => {
        if (res.data.success) {
          if (values.userRoleType === UserRoleType.Editor) {
            navigate("/editorslist");
          } else if (values.userRoleType === UserRoleType.EditorInChief) {
            navigate("/editorsinchieflist");
          } else if (values.userRoleType === UserRoleType.CoEditorInChief) {
            navigate("/coeditorsinchieflist");
          } else if (values.userRoleType === UserRoleType.AssociateEditor) {
            navigate("/associateeditorslist");
          } else if (values.userRoleType === UserRoleType.TechnicalEditor) {
            navigate("/technicaleditorslist");
          } else if (values.userRoleType === UserRoleType.Secretariat) {
            navigate("/secretariatlist");
          } else if (values.userRoleType === UserRoleType.Reviewer) {
            navigate("/reviewerslist");
          } else if (values.userRoleType === UserRoleType.ReviewersBoard) {
            navigate("/reviewersboardlist");
          }
          successToast(res.data.message);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  function handleSave(e) {
    e.preventDefault();
    if (values.userRoleType) {
      setIsRequestBeingMade(true);
      addUser(values)
        .then((res) => {
          if (res.data.success) {
            if (values.userRoleType === UserRoleType.Editor) {
              navigate("/editorslist");
            } else if (values.userRoleType === UserRoleType.EditorInChief) {
              navigate("/editorsinchieflist");
            } else if (values.userRoleType === UserRoleType.CoEditorInChief) {
              navigate("/coeditorsinchieflist");
            } else if (values.userRoleType === UserRoleType.AssociateEditor) {
              navigate("/associateeditorslist");
            } else if (values.userRoleType === UserRoleType.TechnicalEditor) {
              navigate("/technicaleditorslist");
            } else if (values.userRoleType === UserRoleType.Secretariat) {
              navigate("/secretariatlist");
            } else if (values.userRoleType === UserRoleType.Reviewer) {
              navigate("/reviewerslist");
            } else if (values.userRoleType === UserRoleType.ReviewersBoard) {
              navigate("/reviewersboardlist");
            }
            successToast(res.data.message);
          } else {
            errorToast(res.data.message);
          }
        })
        .finally(() => setIsRequestBeingMade(false));
    } else {
      errorToast("You should select a type");
      window.scroll({ top: 0, behavior: "smooth" });
    }
  }

  function handleImageUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const imageData = reader.result?.split(",")[1];
      setValues({ ...values, profilePhoto: imageData });
    };
  }

  return (
    <div className="main-content">
      <Card>
        <h3>{userId ? `Edit user` : "Add New"} </h3>
        <form onSubmit={userId ? handleEdit : handleSave}>
          <div className="row col-md-8 mt-2">
            <div className="col-md-12 mb-2">
              <label>
                Type<i style={{ color: "red" }}>*</i>
              </label>
              <div className="p-0 mx-0 d-flex" style={{ gap: "15px" }}>
                <CheckBoxes
                  label={"Editor-in-Chief"}
                  name={"userRoleType"}
                  value={UserRoleType.EditorInChief}
                  id={"Editor-in-Chief"}
                  checked={values.roles?.some(
                    (item) => item.userRoleType === UserRoleType.EditorInChief
                  )}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  className={"my-1"}
                />

                <CheckBoxes
                  label={"Co-Editor-in-Chief"}
                  name={"userRoleType"}
                  value={UserRoleType.CoEditorInChief}
                  id={"CoEditorInChief"}
                  checked={values.roles?.some(
                    (item) => item.userRoleType === UserRoleType.CoEditorInChief
                  )}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  className={"my-1"}
                />
              </div>
              <div className="p-0 mx-0 d-flex" style={{ gap: "15px" }}>
                <CheckBoxes
                  label={"Editor"}
                  name={"userRoleType"}
                  value={UserRoleType.Editor}
                  id={"editor"}
                  checked={values.roles?.some(
                    (item) => item.userRoleType === UserRoleType.Editor
                  )}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  className={"my-2"}
                />

                <CheckBoxes
                  label={"Associate Editor"}
                  name={"userRoleType"}
                  value={UserRoleType.AssociateEditor}
                  id={"AssociateEditor"}
                  checked={values.roles?.some(
                    (item) => item.userRoleType === UserRoleType.AssociateEditor
                  )}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  className={"my-2"}
                />

                <CheckBoxes
                  label={"Technical Editor"}
                  name={"userRoleType"}
                  value={UserRoleType.TechnicalEditor}
                  id={"TechnicalEditor"}
                  checked={values.roles?.some(
                    (item) => item.userRoleType === UserRoleType.TechnicalEditor
                  )}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  className={"my-2"}
                />

                <CheckBoxes
                  label={"Secretariat"}
                  name={"userRoleType"}
                  value={UserRoleType.Secretariat}
                  id={"Secretariat"}
                  checked={values.roles?.some(
                    (item) => item.userRoleType === UserRoleType.Secretariat
                  )}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  className={"my-2"}
                />
              </div>
              <div className="p-0 mx-0 d-flex" style={{ gap: "15px" }}>
                <CheckBoxes
                  label={"Reviewer"}
                  name={"userRoleType"}
                  value={UserRoleType.Reviewer}
                  id={"Editor-in-Chief"}
                  checked={values.roles?.some(
                    (item) => item.userRoleType === UserRoleType.Reviewer
                  )}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  className={"my-1"}
                />

                <CheckBoxes
                  label={"Reviewer Board"}
                  name={"userRoleType"}
                  value={UserRoleType.ReviewersBoard}
                  id={"ReviewersBoard"}
                  checked={values.roles?.some(
                    (item) => item.userRoleType === UserRoleType.ReviewersBoard
                  )}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  className={"my-1"}
                />
              </div>
              <div className="p-0 mx-0 d-flex" style={{ gap: "15px" }}>
                <CheckBoxes
                  label={"Administrator"}
                  name={"userRoleType"}
                  value={UserRoleType.Admin}
                  id={"Admin"}
                  checked={values.roles?.some(
                    (item) => item.userRoleType === UserRoleType.Admin
                  )}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  className={"my-1"}
                />
              </div>
            </div>
          </div>
          <div className="row col-md-8">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "firstName",
                  id: "firstName",
                  required: true,
                  maxLength: "150",
                }}
                value={values.firstName}
                onChange={onChange}
                label={"First Name"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "middleName",
                  id: "middleName",
                  maxLength: "150",
                }}
                value={values.middleName}
                onChange={onChange}
                label={"Middle Name"}
              />
            </div>
          </div>

          <div className="row col-md-8">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "lastName",
                  id: "lastName",
                  required: true,
                  maxLength: "150",
                }}
                value={values.lastName}
                onChange={onChange}
                label={"Last Name"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "phoneNo",
                  id: "contactNumber",
                  maxLength: "25",
                }}
                value={values.phoneNo}
                onChange={onChange}
                label={"Contact Number"}
              />
            </div>
          </div>

          <div className="row col-md-8">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  name: "email",
                  type: "email",
                  required: true,
                  pattern: "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$",
                  maxLength: "80",
                }}
                value={values.email}
                errorMessage={"Invalid Email"}
                label={"Email"}
                onChange={onChange}
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "title",
                  id: "title",
                  maxLength: "150",
                }}
                value={values.title}
                onChange={onChange}
                label={"Title"}
              />
            </div>
          </div>

          <div className="row col-md-8">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "universityOrOrganization",
                  id: "universityOrOrganization",
                  required: true,
                  maxLength: "350",
                }}
                value={values.universityOrOrganization}
                onChange={onChange}
                label={"University or Organization"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "department",
                  id: "department",
                  maxLength: "350",
                }}
                value={values.department}
                onChange={onChange}
                label={"Department"}
              />
            </div>
          </div>
          <div className="row col-md-8">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "addressLine1",
                  id: "addressLine1",
                  required: true,
                }}
                value={values.addressLine1}
                onChange={onChange}
                label={"Address Line 1"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "addressLine2",
                  id: "addressLine2",
                }}
                value={values.addressLine2}
                onChange={onChange}
                label={"Address Line 2"}
              />
            </div>
          </div>
          <div className="row col-md-8">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "number",
                  name: "zipCode",
                  id: "zipCode",
                  required: true,
                  maxLength: "20",
                }}
                value={values.zipCode}
                onChange={onChange}
                label={"Zip Code"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "city",
                  id: "city",
                  required: true,
                  maxLength: "150",
                }}
                value={values.city}
                onChange={onChange}
                label={"City"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
          </div>
          <div className="row col-md-8">
            <div className="col-md-6 mb-3">
              <label className="mb-1">
                Country<i style={{ color: "red" }}>*</i>
              </label>
              <Select
                menuPlacement="auto"
                options={countriesoptions || countries}
                name="countryId"
                value={countriesoptions.find(
                  (obj) => obj.value === values.countryId
                )}
                onChange={(selectedOption) => {
                  setValues({ ...values, countryId: selectedOption.value });
                }}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "website",
                  id: "website",
                }}
                value={values.website}
                onChange={onChange}
                label={"Website"}
              />
            </div>
          </div>
          <div className="row col-md-8">
            <div className="col-md-6 mb-3">
              <Textarea
                label={"Short Bio"}
                inputProps={{
                  name: "biography",
                  id: "biography",
                }}
                value={values.biography}
                onChange={onChange}
              />
            </div>
            <div className="col-md-6 mb-3">
              <Textarea
                label={"Interests"}
                inputProps={{
                  name: "interests",
                  id: "interests",
                  maxLength: "255",
                }}
                value={values.interests}
                onChange={onChange}
              />
            </div>
          </div>

          <div className="row col-md-8">
            <div className="col-md-6 mb-3">
              <label className="mb-1 w-100">Profile Photo</label>
              {values.profilePhoto ? (
                <div className="d-flex align-items-center justify-content-between form-control">
                  <img
                    src={`data:image;base64,${values.profilePhoto}`}
                    alt="Profile"
                    className="profile-photo"
                    width={50}
                  />
                  <TiDeleteOutline
                    color="red"
                    className="remove-image-icon"
                    onClick={() => {
                      setValues({ ...values, profilePhoto: "" });
                    }}
                  />
                </div>
              ) : (
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={handleImageUpload}
                />
              )}
            </div>
          </div>

          <div className="d-flex justify-content-end  row col-md-8">
            <div
              className="btn me-2 d-flex align-items-center"
              style={{ width: "fit-content" }}
              onClick={() => navigate("/editorslist")}
            >
              Cancel
            </div>
            <ButtonWithLoader isLoading={isRequestBeingMade}>
              {userId ? "Edit" : "Add"} {getUserTypeName(values.userRoleType)}
            </ButtonWithLoader>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default AddEditor;
