import React, { useContext, useState } from "react";
import Card from "../components/Card/Card";
import Input from "../components/Inputs/Input";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { journalLogo, journalName } from "../JournalSettings";
import { login } from "../endpoints/LoginEndpoints";
import { JournalContext } from "../store/JournalContext";
import { errorToast } from "../HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../components/ButtonWithLoader/ButtonWithLoader";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  const { setAppIsReady } = useContext(JournalContext);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    login(email, password)
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem("accessToken", res.data.token);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          navigate("/");
          setAppIsReady(true);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  return (
    <div className="col-md-6 col-10 m-auto">
      <Card>
        <div className="p-5 m-auto">
          {journalLogo ? (
            <img
              src={journalLogo}
              alt="logo"
              height="80px"
              className="d-block m-auto"
            />
          ) : (
            <div style={{ fontWeight: "500", fontSize: "2rem" }}>
              {journalName}
            </div>
          )}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="m-auto w-75">
            <div className="mb-3">
              <Input
                inputProps={{
                  name: "email",
                  type: "text",
                  required: true,
                }}
                value={email}
                label={"Email"}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="mb-3">
              <Input
                inputProps={{
                  name: "password",
                  type: "password",
                  required: true,
                }}
                value={password}
                label={"Password"}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="forgotpassword-link">
              <Link to="/auth/forgot-password">Forgot password?</Link>
            </div>

            <div className="d-flex flex-column align-items-center mb-5">
              <ButtonWithLoader
                isLoading={isRequestBeingMade}
                classNames={["login-btn"]}
              >
                Sign In
              </ButtonWithLoader>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default Login;
