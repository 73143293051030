import React from "react";
import { journalLogo, journalName } from "../JournalSettings";

const Logo = () => {
  return (
    <div className="logo my-3">
      {journalLogo ? (
        <img
          src={journalLogo}
          alt="logo"
          height="40px"
          className="d-block m-auto"
          style={{ cursor: "pointer" }}
        />
      ) : (
        <div
          style={{
            fontWeight: "500",
            color: "white",
            fontSize: "1.2rem",
            textAlign: "center",
            letterSpacing: "10px",
            cursor: "pointer",
          }}
        >
          {journalName}
          <div></div>
        </div>
      )}
    </div>
  );
};

export default Logo;
