import React from "react";
import "./Sidebar.css";
import { Link, NavLink } from "react-router-dom";

const SidebarItem = ({ link, icon, text }) => {
  return (
    <NavLink to={link} className="sidebar-item">
      <div className="py-2 px-3">
        {icon} {text}
      </div>
    </NavLink>
  );
};

export default SidebarItem;
