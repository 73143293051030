import { GUID } from "../JournalSettings";
import { ArticleStatus } from "../Enums";
import "react-toastify/dist/ReactToastify.css";
import instance from "./Instance";

async function getSmtpConfig() {
  return instance
    .get(`/SMPT/GetSMPTConfig?journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function updateSmtpConfig(
  smtpConfigId,
  smtpConfigUser,
  smtpConfigHost,
  smtpConfigPassword,
  smtpConfigDisplayName,
  smtpConfigPort
) {
  const body = {
    journalId: GUID,
    id: smtpConfigId,
    smtpUser: smtpConfigUser,
    SmtpHost: smtpConfigHost,
    SmtpPassword: smtpConfigPassword,
    DisplayName: smtpConfigDisplayName,
    SmtpPort: smtpConfigPort,
  };
  return instance.post("SMPT/UpdateSMTPConfig", body).then((response) => {
    return Promise.resolve(response);
  });
}
export { getSmtpConfig, updateSmtpConfig };
