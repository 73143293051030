import React, { useState, useEffect } from "react";
import Input from "../components/Inputs/Input";
import Card from "../components/Card/Card";
import {
  addOrUpdateVolume,
  getVolumeById,
} from "../endpoints/JournalsEndpoints";
import { useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../components/ButtonWithLoader/ButtonWithLoader";

const AddVolume = () => {
  const { volumeId } = useParams();
  const navigate = useNavigate();
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  const [values, setValues] = useState({
    name: "",
  });

  useEffect(() => {
    if (volumeId) {
      getVolumeById(volumeId)
        .then((volume) => {
          setValues({ name: volume.name, id: volume.id });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setValues({ name: "" });
    }
  }, [volumeId]);

  const handleSave = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    addOrUpdateVolume(values)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          navigate("/volumeslist");
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false))
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEdit = (e) => {
    e.preventDefault();

    setIsRequestBeingMade(true);
    addOrUpdateVolume(values)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          navigate("/volumeslist");
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  function onChange(e) {
    const name = e.target.name || e.target.title;
    let value = e.target.value;

    setValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  return (
    <div className="main-content">
      <Card>
        <h3>{volumeId ? "Edit" : "Add"} Volume</h3>
        <form onSubmit={volumeId ? handleEdit : handleSave}>
          <div className="row mt-2">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "text",
                  name: "name",
                  id: "name",
                  required: true,
                  maxLength: "350",
                }}
                value={values.name}
                onChange={onChange}
                label={"Name"}
                errorMessage={"Field Cannot be Empty"}
              />
              <div className="d-flex justify-content-end mt-2">
                <div
                  className="btn  me-2 d-flex align-items-center"
                  onClick={() => navigate("/volumeslist")}
                >
                  Cancel
                </div>
                <ButtonWithLoader isLoading={isRequestBeingMade}>
                  {values.id ? "Edit" : "Add"} Volume
                </ButtonWithLoader>
              </div>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default AddVolume;
