import { GUID } from "../JournalSettings";
import "react-toastify/dist/ReactToastify.css";
import instance from "./Instance";

async function getAdminDashboard() {
  return instance
    .get(`/Dashboard/GetAdminDashboard?journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

export { getAdminDashboard };
