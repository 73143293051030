import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NewsStatus, Status, UserRoleType, UserStatus } from "../Enums";

function successToast(message) {
  return toast.success(`${message}`, {
    position: toast.POSITION.TOP_RIGHT,
    transition: Zoom,
    autoClose: 2500,
    closeOnClick: true,
    hideProgressBar: true,
  });
}

function errorToast(message) {
  return toast.error(`${message}`, {
    position: toast.POSITION.TOP_RIGHT,
    transition: Zoom,
    autoClose: 2500,
    closeOnClick: true,
    hideProgressBar: true,
  });
}

const handleChangeStatus = (id, statusId, values, setValues, hasUserStatus) => {
  let newValues = [];

  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    if (value.id === id) {
      const updatedValue = hasUserStatus
        ? {
            ...value,
            ...(statusId === UserStatus.Active
              ? { statusId: UserStatus.Passive }
              : { statusId: UserStatus.Active }),
          }
        : {
            ...value,
            ...(statusId === Status.Active
              ? { statusId: Status.Passive }
              : { statusId: Status.Active }),
          };
      newValues.push(updatedValue);
    } else {
      newValues.push(value);
    }
  }
  setValues(newValues);
};

const statusName = (status) => {
  if (status === Status.Active) {
    return "Active";
  } else if (status === Status.Passive) {
    return "Passive";
  }
};

const getNewsStatusName = (status) => {
  if (status === NewsStatus.Published) {
    return "Published";
  } else if (status === NewsStatus.SaveDraft) {
    return "Draft";
  } else if (status === NewsStatus.Passive) {
    return "Passive";
  }
};

const getUserTypeName = (status) => {
  if (status === UserRoleType.AssociateEditor) {
    return "Associate Editor";
  } else if (status === UserRoleType.CoEditorInChief) {
    return "Co-Editor-in-Chief";
  } else if (status === UserRoleType.Editor) {
    return "Editor";
  } else if (status === UserRoleType.EditorInChief) {
    return "Editor-in-Chief";
  } else if (status === UserRoleType.Reviewer) {
    return "Reviewer";
  } else if (status === UserRoleType.ReviewersBoard) {
    return "Reviewer Board";
  } else if (status === UserRoleType.Secretariat) {
    return "Secretariat";
  } else if (status === UserRoleType.TechnicalEditor) {
    return "Technical Editor";
  }
};

export {
  successToast,
  errorToast,
  handleChangeStatus,
  statusName,
  getNewsStatusName,
  getUserTypeName,
};
