import React from "react";
import "./EmptyResponse.css";
import { BsJournalX } from "react-icons/bs";

function EmptyResponse({ message = "There is no data available yet" }) {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column emptyResponse">
      <BsJournalX />
      <p>{message}</p>
    </div>
  );
}

export default EmptyResponse;
