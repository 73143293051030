import React, { useState } from "react";
import "./Header.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdNotificationsNone } from "react-icons/md";
import { FiMaximize } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Header = ({ toggleSidebar, onToggle }) => {
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    navigate("/auth/login");
  };

  return (
    <div className="header-container">
      <div className="m-2" onClick={toggleSidebar}>
        <GiHamburgerMenu size={23} color="gray" />
      </div>
      <div className="d-flex align-items-center m-2">
        <FiMaximize size={20} color="gray" onClick={onToggle} />
        <MdNotificationsNone size={23} color="gray" />

        <div onClick={handleLogOut} className="logoutBtn">
          Log Out
        </div>
      </div>
    </div>
  );
};

export default Header;
