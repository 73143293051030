import React, { useState, useEffect } from "react";
import Card from "../components/Card/Card";
import {
  getSections,
  deleteSection,
  changeSectionStatus,
} from "../endpoints/JournalsEndpoints";
import DeleteModal from "../components/Modals/DeleteModal";
import {
  errorToast,
  statusName,
  successToast,
} from "../HelperFunctions/HelperFunctions";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import EmptyResponse from "../components/EmptyResponse/EmptyResponse";
import Table from "../components/Table/Table";

const SectionsList = () => {
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    getSections()
      .then((res) => {
        const copiedArray = res.data.sections.map((obj) => ({
          name: obj.name,
          topics: obj.sectionResearchTopics
            .map((topic) => topic.name)
            .join(", "),
          statusId: obj.statusId,
          id: obj.id,
        }));
        setSections(copiedArray);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleDeleteSection = (sectionId) => {
    deleteSection(sectionId).then((res) => {
      if (res.data.success) {
        successToast(res.data.message);
        const newSections = sections.filter(
          (obj) => !sectionId.includes(obj.id)
        );
        setSections(newSections);
        toggleModal();
      } else {
        errorToast(res.data.message);
      }
      toggleModal();
    });
  };

  const toggleModal = (sectionId) => {
    setSelectedSectionId(sectionId);
    if (showModal) {
      setShowModal(false);
      document.body.style.overflow = "unset";
    } else {
      setShowModal(true);
      document.body.style.overflow = "hidden";
    }
  };

  const tableHeaders = [
    { value: "Title" },
    { value: "Topics" },
    { value: "Status" },
  ];

  return (
    <div className="main-content">
      <DeleteModal
        show={showModal}
        onCancel={toggleModal}
        onConfirm={() => handleDeleteSection(selectedSectionId)}
        name={"section"}
      />
      <Card>
        <h3>Sections List</h3>
        {loading && (
          <LoadingSpinner
            color={"var(--main800)"}
            height={"30px"}
            width={"30px"}
          />
        )}
        {!loading && sections.length > 0 && (
          <Table
            tableHeaders={tableHeaders}
            tableData={sections}
            editNavigateLink={"/addsection"}
            toggleModal={toggleModal}
            changeStatus={changeSectionStatus}
            setValues={setSections}
          />
        )}
        {!loading && sections.length <= 0 && <EmptyResponse />}
      </Card>
    </div>
  );
};

export default SectionsList;
