import React, { useState, useEffect } from "react";
import Input from "../components/Inputs/Input";
import Card from "../components/Card/Card";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSections,
  addOrUpdateIssue,
  getIssueById,
} from "../endpoints/JournalsEndpoints";
import { errorToast, successToast } from "../HelperFunctions/HelperFunctions";
import Textarea from "../components/Inputs/Textarea";
import ButtonWithLoader from "../components/ButtonWithLoader/ButtonWithLoader";

const AddIssue = () => {
  const { issueId } = useParams();
  const navigate = useNavigate();
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  const [values, setValues] = useState({
    name: "",
    deadline: "",
    description: "",
    sections: "",
  });

  const [issueVolumes, setIssueVolumes] = useState();
  const [issueSections, setIssueSections] = useState();

  useEffect(() => {
    getSections().then((res) => {
      setIssueSections(
        res.data.sections.map((section) => ({
          value: section.id,
          label: section.name,
        }))
      );
    });
  }, []);

  useEffect(() => {
    if (issueId) {
      getIssueById(issueId)
        .then((issue) => {
          setValues({
            id: issueId,
            name: issue.name,
            deadline: issue.deadline,
            description: issue.description,
            sections: issue.journalIssueSections?.map(
              (item) => item.journalSection.id
            ),
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setValues({
        name: "",
        deadline: "",
        description: "",
        sections: "",
      });
    }
  }, [issueId]);

  const handleSave = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    addOrUpdateIssue(values)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          navigate("/issueslist");
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    addOrUpdateIssue(values)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          navigate("/issueslist");
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  function onChange(e) {
    const name = e.target.name || e.target.title;
    let value = e.target.value;

    setValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  return (
    <div className="main-content">
      <Card>
        <h3>{issueId ? "Edit" : "Add"} Issue</h3>
        <form onSubmit={issueId ? handleEdit : handleSave}>
          <div className="row mt-2">
            <div className="col-md-6 mb-3 ">
              <Input
                inputProps={{
                  type: "text",
                  name: "name",
                  id: "name",
                  required: true,
                  maxLength: "350",
                }}
                value={values.name}
                onChange={onChange}
                label={"Name"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="mb-1">
                Sections<i style={{ color: "red" }}>*</i>
              </label>
              <Select
                required
                menuPlacement="auto"
                options={issueSections}
                onChange={(selectedOption) => {
                  setValues({
                    ...values,
                    sections: selectedOption.map((section) => section["value"]),
                  });
                }}
                value={
                  issueSections && values.sections
                    ? issueSections.filter((obj) =>
                        values.sections.includes(obj.value)
                      )
                    : null
                }
                isMulti
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <Input
                inputProps={{
                  type: "date",
                  name: "deadline",
                  id: "deadline",
                  required: true,
                }}
                value={values.deadline?.substring(0, 10)}
                onChange={onChange}
                label={"Deadline"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
            <div className="col-md-6 mb-3">
              <Textarea
                label={"Description"}
                inputProps={{
                  name: "description",
                  id: "description",
                  required: true,
                }}
                value={values.description}
                onChange={onChange}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-2">
            <div
              className="btn me-2 d-flex align-items-center"
              onClick={() => navigate("/issueslist")}
            >
              Cancel
            </div>
            <ButtonWithLoader isLoading={isRequestBeingMade}>
              {issueId ? "Edit" : "Add"} Issue
            </ButtonWithLoader>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default AddIssue;
