import React, { useContext, useEffect, useReducer, useState } from "react";
import SidebarItem from "./SidebarItem";
import { MdDashboardCustomize, MdOutlineArticle } from "react-icons/md";
import { BsJournal, BsPlusLg, BsList, BsJournalText } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { FiUsers, FiFlag, FiSettings } from "react-icons/fi";
import { TiNews } from "react-icons/ti";
import "./Sidebar.css";
import { getAppSettingDetails } from "../../endpoints/AppsettingsEndpoints";
import { MenuType } from "../../Enums";
import { SettingsContext } from "../../store/SettingsContext";

function journalDataDispatcher(state, action) {
  if (action.type === "INITIAL_STATE") {
    return action.data;
  }
  if (action.type === "EDIT_FIELD") {
    const findEditedField = state.find((el) => el.id === action.id);
    if (findEditedField) {
      const indexOfEditedField = state.indexOf(findEditedField);
      const editedObj = state[indexOfEditedField];
      editedObj.value = action.value;
      return [...state];
    }
  }
  if (action.type === "TOGGLE_ACTIVE_FIELD") {
    const findEditedField = state.find((el) => el.id === action.id);
    if (findEditedField) {
      const indexOfEditedField = state.indexOf(findEditedField);
      const editedObj = state[indexOfEditedField];
      editedObj.isActive = !editedObj.isActive;
      return [...state];
    }
  }

  if (action.type === "CHANGE_LANGUAGE") {
    const findEditedField = state.find((el) => el.id === action.id);
    if (findEditedField) {
      const indexOfEditedField = state.indexOf(findEditedField);
      const editedObj = state[indexOfEditedField];
      editedObj.value = action.value;
      return [...state];
    }
  }
  return state;
}

const SidebarMenu = ({ activeDropdown, handleDropdownToggle }) => {
  const [isExpanded, setExpanded] = useState(true);
  const {
    showEditors,
    showEditorsInChief,
    showCoEditorsInChief,
    showAssociateEditors,
    showTechnicalEditors,
    showSecretariatList,
    showReviewers,
    showReviewersBoard,
  } = useContext(SettingsContext);

  const [journalDataMenu2, dispatchJournalDataMenu2] = useReducer(
    journalDataDispatcher,
    []
  );
  useEffect(() => {
    getAppSettingDetails(MenuType.SettingsData)
      .then((res) => {
        dispatchJournalDataMenu2({
          type: "INITIAL_STATE",
          data: res.data.appSettings,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="menu">
      <span className="px-3">MENU</span>
      <ul className="p-0">
        <li>
          <SidebarItem
            link={"/"}
            icon={<MdDashboardCustomize />}
            text={"Dashboard"}
          />
        </li>

        <li>
          <SidebarItem
            link={"/editjournal"}
            icon={<BsJournal />}
            text={"Manage Journal"}
          />
        </li>
        <li>
          <SidebarItem
            link={"/settings"}
            icon={<FiSettings />}
            text={"Journal Settings"}
          />
        </li>
        <li>
          <div
            className="sidebar-item py-2 px-3"
            onClick={() => handleDropdownToggle("editorsDropdown")}
          >
            <BiEditAlt /> Editorial Board
          </div>
          {activeDropdown === "editorsDropdown" && (
            <ul
              className={`sidebarDropdown ${
                isExpanded ? "expanded" : "closed"
              }`}
            >
              {" "}
              <li>
                <SidebarItem
                  link={"/addeditor"}
                  icon={<BsPlusLg />}
                  text={"Add New"}
                />
              </li>
              <li>
                <SidebarItem
                  link={"/editorsinchieflist"}
                  icon={<BsList />}
                  text={"Editors-in-Chief List"}
                />
              </li>
              <li>
                <SidebarItem
                  link={"/coeditorsinchieflist"}
                  icon={<BsList />}
                  text={"Co-Editors-in-Chief List"}
                />
              </li>
              <li>
                <SidebarItem
                  link={"/editorslist"}
                  icon={<BsList />}
                  text={"Editors List"}
                />
              </li>
              <li>
                <SidebarItem
                  link={"/associateeditorslist"}
                  icon={<BsList />}
                  text={"Associate Editors List"}
                />
              </li>
              <li>
                <SidebarItem
                  link={"/technicaleditorslist"}
                  icon={<BsList />}
                  text={"Technical Editors"}
                />
              </li>
              <li>
                <SidebarItem
                  link={"/secretariatlist"}
                  icon={<BsList />}
                  text={"Secretariat List"}
                />
              </li>
              <li>
                <SidebarItem
                  link={"/reviewerslist"}
                  icon={<BsList />}
                  text={"Reviewers List"}
                />
              </li>
              <li>
                <SidebarItem
                  link={"/reviewersboardlist"}
                  icon={<BsList />}
                  text={"Reviewers Board"}
                />
              </li>
            </ul>
          )}
        </li>
        {journalDataMenu2?.find((el) => el.key == "Has Volumes")?.isActive && (
          <li>
            <div
              className="sidebar-item py-2 px-3"
              onClick={() => handleDropdownToggle("volumesDropdown")}
            >
              <BsJournalText /> Volumes
            </div>
            {activeDropdown === "volumesDropdown" && (
              <ul
                className={`sidebarDropdown ${
                  isExpanded ? "expanded" : "closed"
                }`}
              >
                <li>
                  <SidebarItem
                    link={"/addvolume"}
                    icon={<BsPlusLg />}
                    text={"Add Volume"}
                  />
                </li>
                <li>
                  <SidebarItem
                    link={"/volumeslist"}
                    icon={<BsList />}
                    text={"Volumes List"}
                  />
                </li>
              </ul>
            )}
          </li>
        )}
        {journalDataMenu2?.find((el) => el.key == "Has Special Issues")
          ?.isActive && (
          <li>
            <div
              className="sidebar-item py-2 px-3"
              onClick={() => handleDropdownToggle("issuesDropdown")}
            >
              <BsJournalText /> Issues
            </div>
            {activeDropdown === "issuesDropdown" && (
              <ul
                className={`sidebarDropdown ${
                  isExpanded ? "expanded" : "closed"
                }`}
              >
                <li>
                  <SidebarItem
                    link={"/addissue"}
                    icon={<BsPlusLg />}
                    text={"Add Issue"}
                  />
                </li>
                <li>
                  <SidebarItem
                    link={"/issueslist"}
                    icon={<BsList />}
                    text={"Issues List"}
                  />
                </li>
              </ul>
            )}
          </li>
        )}

        {journalDataMenu2?.find((el) => el.key == "Has Sections")?.isActive && (
          <li>
            <div
              className="sidebar-item py-2 px-3"
              onClick={() => handleDropdownToggle("sectionsDropdown")}
            >
              <BsJournalText /> Sections
            </div>
            {activeDropdown === "sectionsDropdown" && (
              <ul
                className={`sidebarDropdown ${
                  isExpanded ? "expanded" : "closed"
                }`}
              >
                <li>
                  <SidebarItem
                    link={"/addsection"}
                    icon={<BsPlusLg />}
                    text={"Add Section"}
                  />
                </li>
                <li>
                  <SidebarItem
                    link={"/sectionslist"}
                    icon={<BsList />}
                    text={"Sections List"}
                  />
                </li>
              </ul>
            )}
          </li>
        )}

        {journalDataMenu2?.find((el) => el.key == "Has Article Types")
          ?.isActive && (
          <li>
            <div
              className="sidebar-item py-2 px-3"
              onClick={() => handleDropdownToggle("articleTypesDropdown")}
            >
              <MdOutlineArticle /> Article Types
            </div>
            {activeDropdown === "articleTypesDropdown" && (
              <ul
                className={`sidebarDropdown ${
                  isExpanded ? "expanded" : "closed"
                }`}
              >
                <li>
                  <SidebarItem
                    link={"/addarticletype"}
                    icon={<BsPlusLg />}
                    text={"Add Article Type"}
                  />
                </li>
                <li>
                  <SidebarItem
                    link={"/articletypeslist"}
                    icon={<BsList />}
                    text={"Article Types List"}
                  />
                </li>
              </ul>
            )}
          </li>
        )}

        <li>
          <div
            className="sidebar-item py-2 px-3"
            onClick={() => handleDropdownToggle("usersDropdown")}
          >
            <FiUsers /> Admin Users
          </div>
          {activeDropdown === "usersDropdown" && (
            <ul
              className={`sidebarDropdown ${
                isExpanded ? "expanded" : "closed"
              }`}
            >
              <li>
                <SidebarItem
                  link={"/adduser"}
                  icon={<BsPlusLg />}
                  text={"Add Admin User"}
                />
              </li>
              <li>
                <SidebarItem
                  link={"/userslist"}
                  icon={<BsList />}
                  text={"Admin Users List"}
                />
              </li>
            </ul>
          )}
        </li>

        <li>
          <SidebarItem
            link={"/editsmtp"}
            icon={<BsJournal />}
            text={"Manage Smtp"}
          />
        </li>

        <li>
          <div
            className="sidebar-item py-2 px-3"
            onClick={() => handleDropdownToggle("countriesDropdown")}
          >
            <FiFlag /> Manage Countries
          </div>
          {activeDropdown === "countriesDropdown" && (
            <ul
              className={`sidebarDropdown ${
                isExpanded ? "expanded" : "closed"
              }`}
            >
              <li>
                <SidebarItem
                  link={"/addcountry"}
                  icon={<BsPlusLg />}
                  text={"Add Country"}
                />
              </li>
              <li>
                <SidebarItem
                  link={"/countrieslist"}
                  icon={<BsList />}
                  text={"Countries List"}
                />
              </li>
            </ul>
          )}
        </li>

        {journalDataMenu2?.find((el) => el.key == "Has News")?.isActive && (
          <li>
            <div
              className="sidebar-item py-2 px-3"
              onClick={() => handleDropdownToggle("newsDropdown")}
            >
              <TiNews /> News
            </div>
            {activeDropdown === "newsDropdown" && (
              <ul
                className={`newsDropdown ${isExpanded ? "expanded" : "closed"}`}
              >
                <li>
                  <SidebarItem
                    link={"/addnews"}
                    icon={<BsPlusLg />}
                    text={"Add News"}
                  />
                </li>
                <li>
                  <SidebarItem
                    link={"/newslist"}
                    icon={<BsList />}
                    text={"News List"}
                  />
                </li>
              </ul>
            )}
          </li>
        )}
      </ul>
    </div>
  );
};

export default SidebarMenu;
