import { useContext, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { JournalContext } from "../../store/JournalContext";
import { BsChevronRight } from "react-icons/bs";

const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ color: [] }, { background: [] }],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  [{ align: [] }, { direction: "rtl" }],
  ["link", "image"],
  ["code-block", "blockquote"],
];

function QuillInput({ label, id }) {
  const { journalData, dispatchJournalData } = useContext(JournalContext);
  const journalFieldObj = journalData.find((el) => el.id === id);
  const [open, setOpen] = useState(journalFieldObj.isActive);

  function handleChange(value) {
    dispatchJournalData({ type: "EDIT_FIELD", value, id });
  }

  function handleToggleActive(event) {
    if (event.target.checked) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    dispatchJournalData({
      type: "TOGGLE_ACTIVE_FIELD",
      id,
    });
  }

  function toggleAccordion() {
    setOpen((prev) => !prev);
  }

  return (
    <div className={`accordionContainer ${open ? "open" : ""}`}>
      <div className="d-flex justify-content-between mb-1">
        <label
          className="mb-1"
          style={{ cursor: "pointer" }}
          onClick={toggleAccordion}
        >
          <BsChevronRight className="chevron" />
          {label}
        </label>
        <label className="switch">
          <input
            type="checkbox"
            checked={journalFieldObj?.isActive}
            onChange={(event) => {
              handleToggleActive(event);
            }}
          />
          <span className="slider"></span>
        </label>
      </div>
      <div className="accordionBody">
        <ReactQuill
          value={journalFieldObj?.value}
          onChange={handleChange}
          modules={{ toolbar: { container: toolbarOptions } }}
          scrollingContainer="#quill-scrolling-container"
        />
      </div>
    </div>
  );
}

export default QuillInput;
