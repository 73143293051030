import React, { useEffect, useRef, useState } from "react";
import "./Sidebar.css";
import Logo from "../Logo";
import SidebarMenu from "./SidebarMenu";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ showSidebar, onClickOutside }) => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();
  const ref = useRef(null);

  useEffect(() => {
    if (window.innerWidth <= 1375) {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          onClickOutside && onClickOutside();
        }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
  }, [onClickOutside]);

  useEffect(() => {
    const handleResize = () => {
      onClickOutside();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDropdownToggle = (dropdownName) => {
    setActiveDropdown((prevDropdown) =>
      prevDropdown === dropdownName ? null : dropdownName
    );
  };

  return (
    <div
      className={`sidebar-container ${!showSidebar ? "open" : "close"}`}
      ref={ref}
    >
      <div onClick={() => navigate("/")}>
        <Logo />
      </div>
      <SidebarMenu
        activeDropdown={activeDropdown}
        handleDropdownToggle={handleDropdownToggle}
      />
    </div>
  );
};

export default Sidebar;
