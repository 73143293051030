import { GUID } from "../JournalSettings";
import instance from "./Instance";

async function getAppSettingDetails(menutype) {
  return instance
    .get(
      `/AppSettings/getAppSettingDetails?journalId=${GUID}&menuId=${menutype}`
    )
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function updateAppSettingValues(
  journalDataMenu2,
  journalCode,
  journalName,
  bannerImages
) {
  const body = {
    journalId: GUID,
    code: journalCode,
    name: journalName,
    bannerImages: bannerImages,
    appsettings: journalDataMenu2,
  };
  return instance
    .post("AppSettings/updateAppSettingValues", body)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function getAppSettingsByRequestType(requestType) {
  return instance
    .get(
      `/AppSettings/getAppSettingsByRequestType?journalId=${GUID}&requestType=${requestType}`
    )
    .then((response) => {
      return Promise.resolve(response.data);
    });
}

export {
  getAppSettingDetails,
  updateAppSettingValues,
  getAppSettingsByRequestType,
};
