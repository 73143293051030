import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="mainWrapper mt-5">
      <main className="d-flex align-items-center justify-content-center card-shadow">
        <div className="errorPage">
          <h1>404</h1>
          <h5>Page Not Found</h5>
          <p>The page you requested could not be found.</p>
          <button onClick={() => navigate("/")} className="btn">
            Go Back
          </button>
        </div>
      </main>
    </div>
  );
};

export default ErrorPage;
